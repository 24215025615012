import React from 'react';

import { cleanValue } from '@amaui/utils';
import { Line, Tooltip, Type, useConfirm, useSnackbars, useSubscription } from '@amaui/ui-react';
import { IBaseElement } from '@amaui/ui-react/types';
import { classNames, style } from '@amaui/style-react';

import IconMaterialCreditCardHeartW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialCreditCardHeartW100';

import { ItemMenu } from 'ui';
import { AuthService, OrganizationService } from 'services';
import { getErrorMessage } from 'utils';
import { ISignedIn } from 'types';

const useStyle = style(theme => ({
  root: {
    position: 'relative',
    width: '100%',
    maxWidth: 340,
    height: 184,
    borderRadius: 8,
    overflow: 'hidden',
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.color.secondary[theme.palette.light ? 40 : 30]}`,
    boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.04)',
    userSelect: 'none'
  },

  main: {
    width: '100%',
    height: '100%',
    padding: 24,
    zIndex: 1
  },

  background: {
    position: 'absolute',
    inset: 0,
    backgroundImage: 'radial-gradient(circle at top left, hsl(177.78deg 100% 81.12%), transparent 400px), radial-gradient(circle at top right, #ff0000, transparent 30%), radial-gradient(at bottom left, #b900ff, transparent 70%), radial-gradient(at bottom right, #05ff00, transparent 700px), radial-gradient(at 60% 57%, #ff3200, transparent 44%)',
    opacity: theme.palette.light ? 0.3 : 0.4,
    filter: 'blur(70px)',
    zIndex: 0
  },

  name: {
    maxWidth: 174,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  ...theme.classes(theme)
}), { name: 'amaui-app-ItemMedia' });

export interface IItemPaymentCard extends IBaseElement {
  object: any;
}

const Element: React.FC<IItemPaymentCard> = React.forwardRef((props, ref: any) => {
  const {
    object,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const confirm = useConfirm();
  const snackbars = useSnackbars();

  const onDefault = React.useCallback(async () => {
    const confirmed = await confirm.open({
      name: `Making payment card default payment method`
    });

    if (!confirmed) return;

    const result = await OrganizationService.updatePaymentMethod(signedIn?.organization?.id, {
      payment_method: {
        id: object.id
      },

      default: true
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Payment card updated`
      });
    }

    // refetch
    OrganizationService.queryPaymentMethods.value!.refetch(true);
  }, [object, signedIn]);

  const onRemove = React.useCallback(async () => {
    const confirmed = await confirm.open({
      name: `Removing payment card`
    });

    if (!confirmed) return;

    const result = await OrganizationService.dettachPaymentMethod({
      payment_method: {
        id: object.id
      }
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Payment card removed`
      });
    }

    // refresh 
    OrganizationService.queryPaymentMethods.value!.refetch(true);
  }, [object]);

  return (
    <Line
      gap={0}

      direction='column'

      justify='space-between'

      className={classNames([
        'amaui-app-ItemPaymentCard',

        className,
        classes.root
      ])}

      {...other}
    >
      <div
        className={classes.background}
      />

      <Line
        gap={1}

        direction='column'

        justify='space-between'

        className={classes.main}

        fullWidth
      >
        <Line
          direction='row'

          align='center'

          justify='space-between'

          fullWidth
        >
          <Line
            gap={1}

            direction='row'

            align='center'
          >
            <Type
              version='t1'
            >
              {cleanValue(object.card.brand, { capitalize: true })}
            </Type>

            {object.default && (
              <Tooltip
                name='Default card'
              >
                <span
                  style={{
                    lineHeight: 0
                  }}
                >
                  <IconMaterialCreditCardHeartW100Rounded
                    color='inherit'

                    size='regular'
                  />
                </span>
              </Tooltip>
            )}
          </Line>

          {object.billing_details.name && (
            <Type
              version='t2'

              whiteSpace='nowrap'

              className={classes.name}
            >
              {object.billing_details.name}
            </Type>
          )}
        </Line>

        <Line
          gap={1}

          direction='column'

          fullWidth
        >
          <Line
            direction='row'

            align='center'

            justify='space-between'

            fullWidth
          >
            <Type
              version='h3'

              weight={400}
            >
              **** **** **** {object.card.last4}
            </Type>
          </Line>

          <Line
            direction='row'

            align='center'

            justify='space-between'

            fullWidth
          >
            <Line
              gap={0.5}

              direction='column'

              fullWidth
            >
              <Type
                version='b2'
              >
                Exp. date
              </Type>

              <Type
                version='l1'
              >
                {object.card.exp_month} / {object.card.exp_year}
              </Type>
            </Line>

            <ItemMenu
              items={[
                ...(!object.default ? [
                  { name: 'Default', onClick: onDefault }
                ] : []),
                { name: 'Remove', onClick: onRemove, props: { color: 'error' } }
              ]}
            />
          </Line>
        </Line>
      </Line>
    </Line>
  );
});

export default Element; 