import React from 'react';

import { useSubscription } from '@amaui/ui-react';

import { Input, NumericTextField, Select } from 'ui';
import { AuthService } from 'services';
import { optionsPayUnit } from 'utils';
import { ISignedIn } from 'types';

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    form,

    ...other
  } = props;

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const currency = signedIn.organization?.settings?.currency || 'EUR';

  return (
    <Input
      name='Pay'

      {...other}
    >
      <Input
        name='Amount'

        size='small'
      >
        <NumericTextField
          placeholder='100'

          value={form.values.pay.value?.value}

          onChange={(valueNew: any) => form.onChange('pay', valueNew, 'value')}

          min={0}

          sufix={currency}

          fullWidth
        />
      </Input>

      <Input
        name='Per'

        description='Booking, Hour options, can optionally be used to automatically create an expense for bookings'

        size='small'
      >
        <Select
          value={form.values.pay.value?.unit}

          onChange={(valueNew: any) => form.onChange('pay', valueNew, 'unit')}

          options={optionsPayUnit}

          fullWidth
        />
      </Input>
    </Input>
  );
});

export default Element;
