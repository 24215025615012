import React from 'react';

import { Avatar } from '@amaui/ui-react';
import { IAvatar } from '@amaui/ui-react/Avatar/Avatar';
import { classNames, style } from '@amaui/style-react';

import { getMediaUrl } from 'utils';

const useStyle = style(theme => ({
  root: {
    '& .amaui-Button-icon-root': {
      width: '100%',
      height: '100%'
    }
  }
}), { name: 'amaui-app-Avatar' });

const Element = React.forwardRef((props: IAvatar, ref: any) => {
  const {
    media,

    className,

    ...other
  } = props;

  const { classes } = useStyle(props);

  // media
  if (media) {
    if (!other.image) other.image = getMediaUrl(media, 140);
  }

  return (
    <Avatar
      ref={ref}

      color='primary'

      className={classNames([
        classes.root,
        className
      ])}

      {...other}
    />
  );
});

Element.displayName = 'amaui-app-Avatar';

export default Element;
