import React from 'react';

import { debounce, hash, is, stringToColor, textToInnerHTML } from '@amaui/utils';
import { Avatar, ListItem, MenuItem, Type, useForm, useSnackbars } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { IAutoComplete } from '@amaui/ui-react/AutoComplete/AutoComplete';

import IconMaterialAddW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialAddW100';

import { getErrorMessage } from 'utils';
import AutoComplete from './AutoComplete';
import { SelectAColor } from '.';

const useStyle = style(theme => ({
  root: {
    '& .amaui-TextField-input-wrapper': {
      flexWrap: 'nowrap',
      overflow: 'auto hidden',

      '&::-webkit-scrollbar': {
        height: 0,
        width: 0
      },

      '& .amaui-AutoComplete-input': {
        flex: '0 0 auto',
        whiteSpace: 'nowrap',
        overflow: 'auto hidden',


        '&::-webkit-scrollbar': {
          height: 0,
          width: 0
        }
      }
    }
  }
}), { name: 'amaui-app-AutoCompleteObjects' });


export interface IAutoCompleteObjects extends IAutoComplete {
  value?: any;

  onAdd?: (object: any) => any;

  onChange: (objects?: any) => any;

  service: any;

  method?: string;

  query?: any;

  avatar?: boolean;

  add?: boolean;

  withColor?: boolean;

  addProps?: any;
}

const Element: React.FC<IAutoCompleteObjects> = React.forwardRef((props, ref: any) => {
  const {
    value: value_,

    onAdd: onAdd_,

    onChange: onChange_,

    onChangeInput: onChangeInput_,

    service,

    method = 'queryPost',

    query,

    add,

    withColor,

    avatar = true,

    addProps,

    className,

    ...other
  } = props;

  const { classes } = useStyle(props);

  const snackbars = useSnackbars();

  const [objects, setObjects] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(false);

  const form = useForm({
    values: {
      name: {
        name: 'Name',
        is: 'string',
        value: other.valueInputDefault || ''
      },
      color: {
        name: 'Color',
        is: 'string'
      }
    }
  });

  const refs = {
    objects: React.useRef(objects),
    method: React.useRef(method),
    query: React.useRef(query),
    input: React.useRef<string>(undefined),
    form: React.useRef(form)
  };

  refs.objects.current = objects;

  refs.method.current = method;

  refs.query.current = query;

  refs.form.current = form;

  const queryUpdated = hash(query);

  const queryObjects = React.useCallback(debounce(async (valueNew: string, initial = false) => {
    if (!initial && refs.input.current === valueNew) return;

    refs.input.current = valueNew;

    setLoading(true);

    const methodService = service[refs.method.current].bind(service);

    const result = await methodService({
      query: {
        name: valueNew,

        ...refs.query.current
      }
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      setObjects(result.response.response?.map((item: any) => ({
        ...item,

        version: undefined,

        name: textToInnerHTML(item.name || ''),

        ...(item.value !== undefined && {
          value_amaui_original: item.value
        }),

        value: item.id
      })));
    }

    setLoading(false);
  }, 440), [service, queryUpdated]);

  const onAdd = async () => {
    await refs.form.current.validate();

    const object = {
      ...refs.form.current.value,

      ...addProps
    };

    setLoading(true);

    const result = await service.add(object);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      setObjects([]);

      refs.form.current.clear();

      const objectAdded = result.response.response;

      objectAdded.id = objectAdded._id || objectAdded.id;

      if (is('function', onAdd_)) onAdd_({
        ...objectAdded,

        value: objectAdded.id
      });
    }

    setLoading(false);
  };

  const init = React.useCallback(() => {
    queryObjects('', true);
  }, [queryUpdated]);

  React.useEffect(() => {
    // init 
    init();
  }, [queryUpdated]);

  const onChange = React.useCallback((valueNew: any) => {
    if (is('function', onChange_)) onChange_(valueNew);
  }, [onChange_]);

  const onChangeInput = React.useCallback((valueNew: any) => {
    // query 
    queryObjects(valueNew);

    refs.form.current.onChange('name', valueNew);

    if (is('function', onChangeInput_)) onChangeInput_!(valueNew);
  }, [onChangeInput_]);

  const value = (is('array', value_) ? value_ : [value_]).filter(Boolean);

  return (
    <AutoComplete
      ref={ref}

      value={value || null}

      valueInput={form.values.name.value || ''}

      onChange={onChange}

      onChangeInput={onChangeInput}

      renderOption={(item: any, index: number, props: any) => (
        <ListItem
          key={index}

          {...props}

          selected={!!value?.find((item_: any) => item_?.value !== undefined ? item_.value === item.value : (item_.id || item_._id) === (item.id || item._id))}

          startAlign='center'

          start={avatar && (
            <Avatar
              tonal

              color={stringToColor(item.name)}

              size='small'
            >
              {item?.name?.slice(0, 1)}
            </Avatar>
          )}

          primary={(
            <Type
              version='b1'

              dangerouslySetInnerHTML={{
                __html: item.name
              }}
            />
          )}
        />
      )}

      options={objects}

      noOptionsElement={add && (
        <MenuItem
          start={<IconMaterialAddW100Rounded size='large' />}

          onClick={onAdd}

          menuCloseOnClick

          primary={(
            <Type
              version='l1'
            >
              {form.value.name}
            </Type>
          )}

          end={withColor && (
            <SelectAColor
              value={form.value.color}

              onChange={(valueNew: any) => form.onChange('color', valueNew)}

              className={'amaui-more'}
            />
          )}

          AsideEndProps={{
            onClick: event => {
              event.preventDefault();
              event.stopPropagation();
            }
          }}

          disabled={loading}

          button
        />
      )}

      noOptions={add && !objects?.length && !!form.value.name?.length}

      chip={false}

      className={classNames([
        classes.root,
        className
      ])}

      {...other}
    />
  );
});

Element.displayName = 'amaui-app-AutoCompleteObjects';

export default Element;
