import React from 'react';
import Helmet from 'react-helmet';

import { capitalize } from '@amaui/utils';
import { useAmauiTheme } from '@amaui/style-react';
import { IBaseElement } from '@amaui/ui-react/types';

export interface IMeta extends IBaseElement {
  app?: string;

  name?: string;
}

const Element: React.FC<IMeta> = props => {
  const {
    name: name_,

    app = 'amaui',

    children
  } = props;

  const theme = useAmauiTheme();

  const name = React.useMemo(() => {
    if (app === 'amaui') return app;

    if (app === 'url-shortener') return 'URL shortener';

    return name_ || capitalize(app);
  }, [name_, app]);

  return (
    <Helmet>
      <title>{name || 'amaui'}</title>

      <link rel='icon' type='image/svg' sizes='32x32' href={`/assets/svg/logos/logo-${app}.svg`} />
      <link rel='icon' type='image/svg' sizes='16x16' href={`/assets/svg/logos/logo-${app}.svg`} />

      <meta name='theme-color' content={(theme.palette.color[app] as any).main} />

      {children}
    </Helmet>
  );
};

export default Element;
