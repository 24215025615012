import React from 'react';
import audioFix from 'webm-duration-fix';

import { innerHTMLToText, is, textToInnerHTML } from '@amaui/utils';
import { Line, Medias, useForm, useSnackbars, useSubscription } from '@amaui/ui-react';
import { classNames, style, useAmauiTheme } from '@amaui/style-react';
import { AmauiDate, format } from '@amaui/date';

import { AudioRecorder, Button, SmartTextField, SpeechToText, TextToSpeech } from 'ui';
import { AuthService, MediaService, NoteService } from 'services';
import { formats, getElementText, getErrorMessage, mediasToValue } from 'utils';
import { ISignedIn } from 'types';

const useStyle = style(theme => ({
  root: {
    '& .amaui-Button-root': {
      flex: '0 0 auto'
    },

    '& .amaui-TextField-input-wrapper': {
      padding: '0 !important',
      height: 'auto !important'
    },

    '& .amaui-Type-root': {
      whiteSpace: 'normal'
    }
  },

  main: {
    height: 0,
    maxHeight: '54vh',
    overflowY: 'auto',

    '& .amaui-TextField-border': {
      boxShadow: 'none'
    }
  },

  audio: {
    maxWidth: '100%',
    maxHeight: 40
  },

  footer: {
    overflowX: 'auto'
  }
}), { name: 'amaui-app-NoteAdd' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    onConfirm,

    onClose: onClose_,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const theme = useAmauiTheme();
  const snackbars = useSnackbars();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [loading, setLoading] = React.useState<any>(false);

  const form = useForm({
    values: {
      'name': {
        name: 'Name',
        value: '',
        max: 1400,
        required: true,
        messages: {
          min: 'Name has to be min 1 characters',
          max: 'Name can be max 1400 characters'
        }
      },
      'value': {
        name: 'Description',
        value: '',
        max: 14000,
        messages: {
          min: 'Description has to be min 1 characters',
          max: 'Description can be max 14000 characters'
        }
      },
      'media': {
        name: 'Media',
        value: [],
        is: 'array',
        of: 'object'
      }
    },

    autoValidate: true
  });

  const refs = {
    name: React.useRef<HTMLElement>(),
    value: React.useRef<HTMLElement>(),
    form: React.useRef(form),
    original: React.useRef<any>()
  };

  refs.form.current = form;

  const onClose = React.useCallback((event?: MouseEvent) => {
    if (is('function', onClose_)) onClose_(event);

    setTimeout(() => {
      refs.form.current.clear();
    }, 1400);
  }, [onClose_]);

  const onNext = React.useCallback(async (event: SubmitEvent) => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      name: innerHTMLToText(refs.form.current.value.name),

      value: [
        {
          version: 'type',
          value: innerHTMLToText(refs.form.current.value.value)
        }
      ],

      media: refs.form.current.value.media
    };

    const result = await NoteService.add(body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Note added`
      });

      if (is('function', onConfirm)) onConfirm();

      onClose();
    }

    setLoading(false);
  }, [form, onConfirm, onClose]);

  const onAudioRecorderConfirm = React.useCallback(async (value: Blob) => {
    setLoading('voice recording');

    const media = await audioFix(value);

    // validate
    // 140 mb maximum
    if (media.size > (140 * 1e6)) {
      snackbars.add({
        color: 'error',
        primary: `Maximum allowed file size is 140 mb`
      });

      return;
    }

    const name = `Voice recording, ${format(new AmauiDate(), formats.entire)}`;

    // meta
    const meta: any = {};

    const result = await MediaService.add({
      name,

      meta,

      app: 'note',

      // media
      media
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      const mediaMongo = result?.response?.response;

      // Update the note
      const medias = refs.form.current.values.media.value || [];

      medias.push(mediasToValue({
        id: mediaMongo?.id,
        name,
        mime: media.type,
        meta: mediaMongo?.meta,
        versions: mediaMongo?.versions,
        added_at: AmauiDate.milliseconds
      }));

      refs.form.current.onChange('media', medias);
    }

    setLoading(false);
  }, [onConfirm]);

  const onSpeechToTextStart = React.useCallback(() => {
    refs.original.current = textToInnerHTML(refs.form.current.values.value.value);
  }, []);

  const onSpeechToTextStop = React.useCallback(() => {
    refs.original.current = '';
  }, []);

  const onSpeechToTextUpdate = React.useCallback((valueNew: any) => {
    refs.value.current!.innerHTML = refs.original.current;

    const div = window.document.createElement('div');

    div.innerHTML = valueNew;

    refs.value.current!.append(div);

    refs.form.current.onChange('value', innerHTMLToText(refs.value.current!.innerHTML));
  }, []);

  const propsOther: any = {
    size: 'small'
  };

  return (
    <Line
      gap={2}

      flex

      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      <Line
        gap={4}

        flex

        fullWidth

        className={classes.main}
      >
        <Line
          gap={1}

          justify='unset'

          align='unset'

          flex

          fullWidth
        >
          <SmartTextField
            ref={refs.name}

            placeholder='Name'

            onChange={(valueNew: string) => form.onChange('name', textToInnerHTML(valueNew))}

            additional={{
              version: 't2'
            }}

            style={{
              whiteSpace: 'normal'
            }}
          />

          <SmartTextField
            ref={refs.value}

            placeholder='Description'

            onChange={(valueNew: string) => form.onChange('value', valueNew)}

            additional={{
              version: 'b2'
            }}

            edit

            multiline

            mention
          />
        </Line>

        <Medias
          values={form.values.media.value?.map((item: any) => ({
            value: item
          }))}
        />
      </Line>

      <Line
        direction='row'

        justify='space-between'

        fullWidth

        className={classes.footer}
      >
        <Button
          version='text'

          color='inherit'

          onClick={onClose}

          disabled={loading === true}

          {...propsOther}
        >
          Close
        </Button>

        <Line
          gap={1}

          direction='row'

          align='center'
        >
          <SpeechToText
            onData={onSpeechToTextUpdate}

            onChange={onSpeechToTextUpdate}

            onStart={onSpeechToTextStart}

            onStop={onSpeechToTextStop}

            language={signedIn?.organization?.personalization?.settings?.languages?.speech}

            disabled={loading}

            IconProps={{
              size: 'regular'
            }}

            {...propsOther}
          />

          {!!form.value.value?.length && (
            <TextToSpeech
              read={getElementText(textToInnerHTML(form.value.value))}

              language={signedIn?.organization?.personalization?.settings?.languages?.speech}

              IconProps={{
                size: 'regular'
              }}

              {...propsOther}
            />
          )}

          <AudioRecorder
            onConfirm={(value: Blob) => onAudioRecorderConfirm(value)}

            disabled={loading}

            IconProps={{
              size: 'regular'
            }}

            {...propsOther}
          />

          <Button
            color={theme.palette.light ? 'default' : 'inverted'}

            elevation={false}

            onClick={onNext}

            loading={loading === true}

            {...propsOther}
          >
            Next
          </Button>
        </Line>
      </Line>
    </Line>
  );
});

export default Element;
