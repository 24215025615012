import React from 'react';

import { DatePicker } from '@amaui/ui-react'
import { IDatePicker } from '@amaui/ui-react/DatePicker/DatePicker';

import IconMaterialEditW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialEditW100';
import IconMaterialCalendarTodayW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialCalendarTodayW100';
import IconMaterialCloseW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialCloseW100';

const Element: React.FC<IDatePicker> = props => {

  return (
    <DatePicker
      size='small'

      Icon={IconMaterialCalendarTodayW100Rounded}

      IconEnter={IconMaterialEditW100Rounded}

      IconClose={IconMaterialCloseW100Rounded}

      IconProps={{
        size: 'regular'
      }}

      {...props}
    />
  );
};

export default Element;
