import { UrlShortener } from '@amaui/api-utils';

import { BaseService } from './base';

class URLShortenerService extends BaseService<UrlShortener> {

  public constructor() {
    super('urlShorteners');
  }

}

export default new URLShortenerService();
