import React from 'react';

import { TextToSpeech } from '@amaui/ui-react'
import { ITextToSpeech } from '@amaui/ui-react/TextToSpeech/TextToSpeech';

import IconMaterialTextToSpeechRounded from '@amaui/icons-material-rounded-react/IconMaterialTextToSpeechW100';
import IconMaterialPauseRounded from '@amaui/icons-material-rounded-react/IconMaterialPauseW100';

const Element: React.FC<ITextToSpeech> = props => {

  return (
    <TextToSpeech
      Icon={IconMaterialTextToSpeechRounded}

      IconPause={IconMaterialPauseRounded}

      IconProps={{
        size: 'large'
      }}

      {...props}
    />
  );
};

export default Element;
