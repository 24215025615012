import React from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

import { is } from '@amaui/utils';
import { Line, useForm, useSnackbars, useSubscription } from '@amaui/ui-react';
import { style, useAmauiTheme } from '@amaui/style-react';

import { ModalForm } from 'ui';
import { AppService, AuthService, OrganizationService } from 'services';
import { FONT_FAMILY, getErrorMessage } from 'utils';
import { ISignedIn } from 'types';

const useStyle = style(theme => ({
  root: {
    '& .ElementsApp, & .ElementsApp .InputElement': {
      color: theme.methods.palette.color.value('primary', 5)
    }
  },

  card: {
    width: '100%',
    padding: 16,
    border: `1px solid ${theme.methods.palette.color.colorToRgb(theme.methods.palette.color.value('primary', 10), 40)}`,
    borderRadius: 8
  }
}), { name: 'amaui-app-PaymentMethod' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    onConfirm,

    onClose: onClose_
  } = props;

  const { classes } = useStyle();

  const stripe = useStripe();
  const elements = useElements();

  const snackbars = useSnackbars();
  const theme = useAmauiTheme();
  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [loading, setLoading] = React.useState(false);

  const CARD_ELEMENT_OPTIONS: any = {
    style: {
      base: {
        color: theme.methods.palette.color.value('primary', 5),
        fontFamily: FONT_FAMILY.secondary,
        fontSmoothing: 'antialiased',
        fontSize: '16px',

        '::placeholder': {
          color: theme.methods.palette.color.colorToRgb(theme.methods.palette.color.value('primary', 5), 70),
        }
      },
      invalid: {
        color: theme.palette.color.error.main,
        iconColor: theme.palette.color.error.main,
      }
    }
  };

  const form = useForm({
    values: {
      'card': {
        name: 'Card',
        equal: true,
        required: true
      }
    }
  });

  const noStripe = !stripe || !elements;

  const onClose = React.useCallback(() => {
    // clean up
    form.clear();

    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });

    if (is('function', onClose_)) onClose_();
  }, [onClose_]);

  React.useEffect(() => {
    if (elements) {
      const cardElement = elements!.getElement(CardElement);

      cardElement!.on('change', async event => {
        await form.onChange('card', event.complete);

        await form.validate();
      });
    }
  }, [elements]);

  const onSubmit = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    setLoading(true);

    const setup_intent = await OrganizationService.addSetupIntent();

    let result = await stripe!.confirmCardSetup(setup_intent.response.response.client_secret, {
      payment_method: {
        card: elements!.getElement(CardElement) as any,
        billing_details: {
          name: signedIn?.user?.name
        }
      }
    });

    if (result.error) {
      snackbars.add({
        color: 'error',
        primary: result.error.message
      });
    }
    else {
      const resultAPI = await OrganizationService.attachPaymentMethod({
        payment_method: {
          id: result.setupIntent.payment_method as string
        },

        default: true
      });

      if (resultAPI.status >= 400) {
        snackbars.add({
          color: 'error',
          primary: getErrorMessage(resultAPI)
        });
      }
      else {
        snackbars.add({
          primary: 'Payment method added'
        });

        if (is('function', onConfirm)) onConfirm();

        onClose();
      }
    }

    setLoading(false);
  }, [signedIn, stripe, onConfirm, onClose]);

  const modal: any = {
    write: <>
      <Line
        fullWidth
      >
        <CardElement
          options={CARD_ELEMENT_OPTIONS}

          className={classes.card}
        />
      </Line>
    </>
  };

  return (
    <ModalForm
      {...props}

      name='Payment method'

      add

      {...modal}

      onSubmit={onSubmit}

      onNext={onSubmit}

      onClose={onClose}

      loading={loading}

      NextProps={{
        disabled: noStripe || !form.valid
      }}
    />
  );
});

export default Element;
