import React from 'react';

import { Interaction, Line, Surface, Type } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { copyToClipboard } from '@amaui/utils';

const useStyle = style(theme => ({
  root: {
    position: 'relative',
    width: 'auto',
    padding: 16,
    borderRadius: 12,
    cursor: 'pointer',
    userSelect: 'none',
    transition: theme.methods.transitions.make('transform'),

    '&.amaui-Surface-root': {
      background: 'transparent'
    },

    '&:active': {
      transform: 'scale(0.94)'
    }
  }
}), { name: 'amaui-app-AnalyticValue' });

const AnalyticValue = React.forwardRef((props: any, ref: any) => {
  const {
    name,

    value,

    className,

    children,

    ...other
  } = props;

  const { classes } = useStyle();

  const onClick = React.useCallback(async () => {
    await copyToClipboard(value);
  }, [value]);

  return (
    <Line
      ref={ref}

      gap={0}

      direction='column'

      fullWidth

      color='primary'

      tonal

      Component={Surface}

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      <Interaction />

      <Line
        ref={ref}

        gap={1}

        direction='column'

        align='center'

        onClick={onClick}
      >
        <Type
          version='b1'

          align='center'
        >
          {name}
        </Type>

        <Type
          version='t2'

          align='center'
        >
          {value} visit{value !== 1 ? 's' : ''}
        </Type>
      </Line>
    </Line>
  );
});

export default AnalyticValue;
