import React from 'react';

import { is } from '@amaui/utils';
import { Expand, IconButton, Line, Tooltip, Type } from '@amaui/ui-react';
import { IBaseElement, ISize } from '@amaui/ui-react/types';
import { useAmauiTheme } from '@amaui/style-react';

import IconMaterialExpandMoreW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialExpandMoreW100';
import { Divider } from '.';

export interface IInput extends IBaseElement {
  name?: any;

  description?: any;

  gapMain?: any;

  iconName?: any;

  iconDescription?: any;

  expand?: boolean;

  footer?: any;

  center?: boolean;

  maxWidth?: boolean | number;

  startName?: any;

  endName?: any;

  size?: ISize;

  weight?: number;

  divider?: boolean;

  NameProps?: any;

  MainProps?: any;
}

const Element: React.FC<IInput> = React.forwardRef((props, ref: any) => {
  const {
    name,

    description,

    size = 'regular',

    gapMain: gapMainProps,

    iconName,

    iconDescription,

    startName,

    endName,

    expand,

    footer,

    center,

    weight,

    maxWidth,

    divider,

    NameProps,

    MainProps,

    children,

    className,

    style,

    ...other
  } = props;

  const theme = useAmauiTheme();

  const [expanded, setExpanded] = React.useState(!expand);

  const onExpand = React.useCallback(() => {
    setExpanded(item => !item);
  }, []);

  const items = React.Children.toArray(children);

  let gapMain = size === 'large' ? 6 : size === 'regular' ? 4 : 2;

  if (divider) gapMain = size === 'large' ? 3 : size === 'regular' ? 2 : 1;

  const main = (
    <Line
      gap={gapMainProps !== undefined ? gapMainProps : gapMain}

      fullWidth

      {...MainProps}
    >
      {items.flatMap((item, index) => !divider ? item : [item, index < items.length - 1 ? <Divider size='small' /> : null])}
    </Line>
  );

  const withHeader = name || description;

  return (
    <Line
      gap={size === 'large' ? 6 : size === 'regular' ? 4 : 2}

      fullWidth

      style={{
        ...(maxWidth !== undefined && {
          maxWidth: is('number', maxWidth) ? maxWidth : theme.maxWidth
        }),

        ...style
      }}

      {...other}
    >
      {withHeader && (
        <Line
          gap={0.5}

          fullWidth
        >
          {name && (
            <Line
              gap={2}

              direction={{
                default: 'row',
                xxs: 'column',
                xs: 'column',
                sm: 'column'
              }}

              align='space-between'

              fullWidth

              {...NameProps}
            >
              <Line
                gap={2}

                direction='row'

                wrap='wrap'

                align='center'

                fullWidth
              >
                {startName}

                <Line
                  gap={1}

                  direction='row'

                  align='center'
                >
                  {iconName}

                  {is('string', name) ? (
                    <Type
                      version={size === 'large' ? 'h2' : size === 'regular' ? 'h3' : 't1'}

                      weight={weight !== undefined ? weight : 600}

                      dangerouslySetInnerHTML={{
                        __html: name
                      }}
                    />
                  ) : name}
                </Line>
              </Line>

              {(expand || endName) && (
                <Line
                  gap={1}

                  direction='row'

                  wrap='wrap'

                  align='center'
                >
                  {endName}

                  {expand && (
                    <Tooltip
                      name={expanded ? 'Hide' : 'Open'}
                    >
                      <IconButton
                        onClick={onExpand}
                      >
                        <IconMaterialExpandMoreW100Rounded
                          size='large'

                          style={{
                            ...(expanded && { transform: 'rotate(180deg)' })
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </Line>
              )}
            </Line>
          )}

          {description && (
            <Line
              gap={1}

              direction='row'

              align='center'
            >
              {iconDescription}

              {is('string', description) ? (
                <Type
                  version={size === 'large' ? 'b1' : size === 'regular' ? 'b1' : 'b2'}

                  weight={200}

                  dangerouslySetInnerHTML={{
                    __html: description
                  }}
                />
              ) : description}
            </Line>
          )}
        </Line>
      )}

      {children && <>
        {!expand ? main : (
          <Expand
            in={expanded}
          >
            {main}
          </Expand>
        )}
      </>}

      {footer && (
        <Line
          gap={1}

          direction='row'

          align='center'

          justify={center ? 'center' : 'flex-end'}

          fullWidth

          style={{
            marginTop: 10
          }}
        >
          {footer}
        </Line>
      )}
    </Line>
  );
});

export default Element;
