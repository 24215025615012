import { Integration } from '@amaui/api-utils';

import { BaseService } from './base';

class IntegrationService extends BaseService<Integration> {

  public constructor() {
    super('integrations');
  }

}

export default new IntegrationService();
