import React from 'react';

import { capitalize } from '@amaui/utils';
import { IconButton, Line, SpeedDial, SpeedDialItem, Tooltip, Type, useSubscription } from '@amaui/ui-react';
import { classNames, style, useAmauiTheme } from '@amaui/style-react';

import IconMaterialKeyboardArrowDownRounded from '@amaui/icons-material-rounded-react/IconMaterialKeyboardArrowDownW100';
import IconMaterialTempPreferencesCustomRounded from '@amaui/icons-material-rounded-react/IconMaterialTempPreferencesCustomW100';
import IconMaterialTaskAltRounded from '@amaui/icons-material-rounded-react/IconMaterialTaskAltW100';
import IconMaterialShortTextRounded from '@amaui/icons-material-rounded-react/IconMaterialShortTextW100';

import { ReactComponent as LogoTask } from 'assets/svg/logos/logo-task.svg';
import { ReactComponent as LogoNote } from 'assets/svg/logos/logo-note.svg';

import { FormNoteAdd, FormTaskAdd } from 'ui';
import { AuthService, NoteService, TaskService } from 'services';
import { ISignedIn } from 'types';

const useStyle = style(theme => ({
  root: {

  },

  speedDial: {
    '&.amaui-SpeedDial-root': {
      insetInlineStart: '16px',
      bottom: '16px'
    }
  },

  widget: {
    width: '100vw',
    maxWidth: 314,
    height: '100vh',
    maxHeight: 400,
    borderRadius: 8,
    background: theme.palette.background.default.primary,
    boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.04)',
    padding: '20px 20px 16px'
  },

  metaLogo: {
    height: 24,
    width: 'auto'
  }
}), { name: 'amaui-app-WidgetMain' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const theme = useAmauiTheme();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [open, setOpen] = React.useState<any>(false);
  const [widget, setWidget] = React.useState<string>('task');

  const refs = {
    quick: React.useRef<any>()
  };

  const onOpen = React.useCallback((value: any) => {
    setOpen(true);

    setWidget(value);
  }, []);

  const onClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  React.useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') onClose();
    };

    window.document.addEventListener('keydown', onKeyDown);

    return () => {
      window.document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  const onConfirmTask = React.useCallback(() => {
    TaskService.refetch();
  }, []);

  const onConfirmNote = React.useCallback(() => {
    NoteService.refetch();
  }, []);

  const elementProps: any = {
    onClose
  };

  const allowed: any = {};

  if (signedIn.user.is.user) allowed.task = true;

  if (signedIn.user.is.user) allowed.note = true;

  let element: any = (
    <FormTaskAdd
      onConfirm={onConfirmTask}

      {...elementProps}
    />
  );

  if (widget === 'note') element = (
    <FormNoteAdd
      onConfirm={onConfirmNote}

      {...elementProps}
    />
  );

  const name = React.useCallback((value: any) => {
    return capitalize(value);
  }, []);

  if (!Object.values(allowed).length) return null;

  let icon = <LogoNote className={classes.metaLogo} />;

  if (widget === 'task') icon = <LogoTask className={classes.metaLogo} />;

  return <>
    <Tooltip
      open={open}

      anchorElement={refs.quick.current}

      position='top'

      alignment='start'

      style={{
        paddingInlineStart: 72,
        zIndex: 1400
      }}

      name={(
        <Line
          gap={1.5}

          justify='unset'

          align='unset'

          fullWidth

          className={classes.widget}
        >
          <Line
            direction='row'

            justify='space-between'

            align='center'
          >
            <Line
              gap={1.25}

              direction='row'

              align='center'
            >
              {icon}

              <Type
                version='t2'

                whiteSpace='nowrap'
              >
                {name(widget)}
              </Type>
            </Line>

            <Tooltip
              name='Close'
            >
              <IconButton
                color='default'

                size='small'

                onClick={() => setOpen(false)}
              >
                <IconMaterialKeyboardArrowDownRounded
                  size='regular'
                />
              </IconButton>
            </Tooltip>
          </Line>

          <Line
            justify='unset'

            align='unset'

            fullWidth

            flex
          >
            {element}
          </Line>
        </Line>
      )}

      AppendProps={{
        update: theme.direction
      }}
    />

    <SpeedDial
      ref={refs.quick}

      tooltipLabel='Quick'

      position='bottom'

      alignment='start'

      direction='right'

      Icon={IconMaterialTempPreferencesCustomRounded}

      className={classNames([
        classes.root,
        classes.speedDial
      ])}

      {...other}
    >
      {allowed.task && (
        <SpeedDialItem
          key={1}

          name='Task'

          Icon={IconMaterialTaskAltRounded}

          onClick={() => onOpen('task')}

          selected={open === 'task'}
        />
      )}

      {allowed.note && (
        <SpeedDialItem
          key={2}

          name='Note'

          Icon={IconMaterialShortTextRounded}

          onClick={() => onOpen('note')}

          selected={open === 'note'}
        />
      )}
    </SpeedDial>
  </>;
});

export default Element;
