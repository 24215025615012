import React from 'react';

import { Image, Line, Type, useConfirm, useForm, useSnackbars, useSubscription } from '@amaui/ui-react';
import { ValidationError } from '@amaui/errors';

import IconMaterialQrCodeScannerW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialQrCodeScannerW100';
import IconMaterialAccountCircleOffW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialAccountCircleOffW100';
import IconMaterialContractDeleteW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialContractDeleteW100';
import IconMaterialShieldW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialShieldW100';

import { Button, Divider, Input, Paper, TextField } from 'ui';
import { AuthService, SignInService, UserService } from 'services';
import { getErrorMessage } from 'utils';
import { ISignedIn } from 'types';

const Element = React.forwardRef(() => {
  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const snackbars = useSnackbars();
  const confirm = useConfirm();

  const [loading, setLoading] = React.useState<any>(false);
  const [MFAAppImage, setMFAAppImage] = React.useState<any>();

  const form = useForm({
    values: {
      'password': {
        name: 'password',
        value: '',
        isValid: 'password',
        messages: {
          isValid: 'Password must have min 7 characters, with at least one lowercase, uppercase and a number character'
        },
        required: true
      },
      'password_confirm': {
        name: 'password confirm',
        value: '',
        required: true,
        method: [
          (value, { form: formCurrent }) => {
            const password = formCurrent.values['password'];

            if (value !== password.value) throw new ValidationError(`Password confirm has to match the password`)
          }
        ]
      }
    }
  });

  const refs = {
    form: React.useRef(form)
  };

  refs.form.current = form;

  const onUpdatePassword = React.useCallback(async () => {
    const valid = await refs.form.current.validate();

    if (!valid) return;

    setLoading('password');

    const result = await UserService.updatePassword({
      user: {
        settings: {
          security: {
            password: refs.form.current.value.password
          }
        }
      }
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: result.response?.meta?.message || 'Updated'
      });

      // clean up
      refs.form.current.clear();
    }

    setLoading(false);
  }, []);

  const onActivateMFAApp = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    setLoading('mfa-app-activate');

    const result = await UserService.mfaAppActivate();

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: result.response?.meta?.message || 'MFA using authenticator app activated'
      });

      setMFAAppImage(result.response.response);

      await AuthService.me();
    }

    setLoading(false);
  }, [form]);

  const onInactivateMFAApp = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    setLoading('mfa-app-inactivate');

    const result = await UserService.mfaAppInactivate();

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: result.response?.meta?.message || 'MFA using authenticator app inactivated'
      });

      setMFAAppImage('');

      await AuthService.me();
    }

    setLoading(false);
  }, [form]);

  const onRemoveAccount = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    setLoading('remove');

    const confirmed = await confirm.open({
      name: 'Remove account',
      description: `Are you sure you want to remove your account, everything will be permanently deleted?`,

      buttons: {
        positive: {
          text: 'Remove'
        },
        negative: {
          text: 'Cancel'
        }
      },

      ButtonPositiveProps: {
        color: 'error'
      }
    });

    if (!confirmed) {
      setLoading(false);

      return;
    }

    const result = await UserService.remove();

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: result.response?.meta?.message || 'Account removed'
      });

      // user sign out
      await AuthService.signOut(false);
    }

    setLoading(false);
  }, []);

  const onRemoveAllSignIns = React.useCallback(async () => {
    const confirmed = await confirm.open({
      name: 'Remove all sign ins',
      description: `You are about to remove all sign ins, are you sure?`
    });

    if (!confirmed) return;

    const result = await SignInService.removeAll();

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `All sign ins removed`
      });
    }

    // refetch
    SignInService.refetch();
  }, []);

  const MFAApp = signedIn?.user?.settings?.security?.mfa?.app;

  const buttonProps: any = {
    version: 'outlined'
  };

  return (
    <Paper
      name='Security'
    >
      <Input
        name='Password'

        description='Update password'

        footer={(
          <Button
            color='inherit'

            onClick={onUpdatePassword}

            loading={loading === 'password'}

            {...buttonProps}
          >
            Update password
          </Button>
        )}
      >
        <TextField
          key='password'

          name='Password'

          type='password'

          value={form.values['password'].value}

          error={form.values['password'].error}

          helperText={form.values['password'].error}

          onChange={(valueNew: any) => form.onChange('password', valueNew)}

          fullWidth
        />

        <TextField
          key='password_confirm'

          name='Password Confirm'

          type='password'

          value={form.values['password_confirm'].value}

          error={form.values['password_confirm'].error}

          helperText={form.values['password_confirm'].error}

          onChange={(valueNew: any) => form.onChange('password_confirm', valueNew)}

          fullWidth
        />
      </Input>

      <Divider />

      <Input
        name='Sign ins'

        iconName={(
          <IconMaterialAccountCircleOffW100Rounded
            size='large'
          />
        )}

        description='If you think someone signed in your account, you can sign out all devices. We advise to update password first and then sign in with new credentials.'

        footer={(
          <Button
            color='error'

            onClick={onRemoveAllSignIns}

            {...buttonProps}
          >
            Sign out all devices
          </Button>
        )}
      />

      <Divider />

      <Input
        name='Multi factor authentication (MFA)'

        iconName={(
          <IconMaterialShieldW100Rounded
            color='success'

            size='large'
          />
        )}

        description='Make your authentication more secure, with additional authentication, using an authenticator app ie. Google Authenticator app'

        footer={((!MFAApp && !MFAAppImage) || MFAApp) && <>
          {!MFAApp && !MFAAppImage && (
            <Button
              color='success'

              onClick={onActivateMFAApp}

              disabled={loading === 'mfa-app-activate'}

              {...buttonProps}
            >
              Enable MFA Authentication
            </Button>
          )}

          {MFAApp && (
            <Button
              color='error'

              onClick={onInactivateMFAApp}

              disabled={loading === 'mfa-app-inactivate'}

              {...buttonProps}
            >
              Remove MFA Authentication
            </Button>
          )}
        </>}
      >
        {MFAAppImage && (
          <Line
            gap={1}

            align='flex-start'

            fullWidth
          >
            <Line
              gap={0.5}

              fullWidth
            >
              <Line
                gap={1}

                direction='row'

                align='center'

                fullWidth
              >
                <IconMaterialQrCodeScannerW100Rounded />

                <Type
                  version='b1'

                  weight={400}
                >
                  QR code scan
                </Type>
              </Line>

              <Type
                version='b2'
              >
                Use app like Google Authenticator app and scan the QR code
              </Type>
            </Line>

            <Image
              src={MFAAppImage}

              alt=''

              align='start'
            />
          </Line>
        )}
      </Input>

      <Divider />

      <Input
        name='Remove account'

        iconName={(
          <IconMaterialContractDeleteW100Rounded
            color='error'

            size='large'
          />
        )}

        description={(
          <Type
            version='b2'

            style={{
              marginTop: 4
            }}
          >
            Removing the account, we will remove all organizations you are owner of and all the content created within those organizations, including bookings, clients, tasks, notes, websites, etc. <br /><br />

            Each organization subscription will be automatically cancelled. <br /><br />

            This action cannot be undone.
          </Type>
        )}

        footer={(
          <Button
            color='error'

            onClick={onRemoveAccount}

            disabled={loading}

            {...buttonProps}
          >
            Remove account
          </Button>
        )}
      />
    </Paper>
  );
});

export default Element;
