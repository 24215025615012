import React from 'react';

import { Line, Type } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';

import { colorOptions } from 'utils';

const useStyle = style(theme => ({
  root: {
    cursor: 'default',
    userSelect: 'none'
  },

  withClick: {
    cursor: 'pointer',
    transition: theme.methods.transitions.make('transform'),

    '&:active': {
      transform: 'scale(0.94)'
    }
  },

  ...theme.classes(theme)
}), { name: 'amaui-app-Color' });

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    color,

    onClick,

    noName = true,

    className,

    ...other
  } = props;

  const { classes } = useStyle(props);

  const value = React.useMemo(() => {
    return colorOptions[color];
  }, [colorOptions, color]);

  if (!(value && color)) return null;

  const withClick = !!onClick;

  return (
    <Line
      gap={1.5}

      direction='row'

      algin='center'

      onClick={onClick}

      tabIndex={onClick ? 0 : -1}

      className={classNames([
        className,
        classes.root,
        withClick && classes.withClick
      ])}

      {...other}
    >
      <Line
        className={classNames([
          classes.palette,
          !value.color && classes.emptyColor
        ])}

        style={{
          background: value?.color
        }}
      />

      {!noName && (
        <Type
          version='b3'
        >
          {value.name}
        </Type>
      )}
    </Line>
  );
});

Element.displayName = 'amaui-app-Avatar';

export default Element;
