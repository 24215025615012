import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Divider, Form, FormRow, Line, Link, Type, useForm, useQuery, useSnackbars, useSubscription } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { ISignIn, ISignInGoogle } from '@amaui/sdk/other';

import { ReactComponent as IconGoogleSymbol } from 'assets/svg/google-logo-symbol.svg';

import { Button, TextField } from 'ui';
import { AuthService, UserService } from 'services';
import { getDeviceAndLocation, getErrorMessage, getRootPage, googleLibrary } from 'utils';
import { ISignedIn } from 'types';

const useStyle = style(theme => ({
  root: {},

  buttons: {
    marginTop: 30
  },

  buttonGoogle: {
    backgroundColor: theme.palette.light ? theme.palette.color.primary[100] : theme.palette.color.primary[10],
    transition: `${[theme.methods.transitions.make(['color', 'box-shadow']), theme.methods.transitions.make('transform', { duration: 'rg' })].join(', ')} !important`,

    '&:active': {
      transform: 'scale(0.97)'
    }
  },

  iconGoogle: {
    width: 'auto',
    height: 18,
    marginRight: 4
  }
}), { name: 'amaui-app-route-SignIn' });

// Todo
// add MFA as a step if required
const SignIn = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const query = useQuery();
  const navigate = useNavigate();
  const snackbars = useSnackbars();
  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [step, setStep] = React.useState(1);
  const [loading, setLoading] = React.useState(false);

  const formSignIn = useForm({
    values: {
      'user.email': {
        name: 'email',
        isValid: 'email',
        value: UserService.invite?.email,
        required: true
      },
      'user.settings.security.password': {
        name: 'password',
        required: true
      }
    }
  });

  const formMFA = useForm({
    values: {
      'mfa': {
        name: 'Authentication code',
        required: true
      }
    }
  });

  const refs = {
    amaui: React.useRef<any>(),
    formSignIn: React.useRef(formSignIn),
    formMFA: React.useRef(formMFA)
  };

  refs.formSignIn.current = formSignIn;

  refs.formMFA.current = formMFA;

  const signInUser = React.useCallback(async (value: string) => {
    // google modal close
    (window as any).google.accounts.id.cancel();

    await AuthService.init(value);

    // redirect to
    const redirect_to = query.get('redirect_to');

    if (redirect_to) navigate(redirect_to);
    else {
      // or to home
      navigate(getRootPage(signedIn));
    }
  }, [query]);

  const onSignInGoogle = React.useCallback(async (...args: any[]) => {
    const googleResponse = args[0];

    const token = googleResponse.access_token || googleResponse.credential;

    const valid = !!token;

    if (!valid) return;

    setLoading(true);

    const invite = UserService.invite || query.get('invite');

    const body: ISignInGoogle = {
      ...(await getDeviceAndLocation()),

      google: {
        access_token: googleResponse.access_token,
        credential: googleResponse.credential
      }
    };

    if (invite) body.invite = { id: invite?.id || invite };

    const result = await AuthService.signInGoogle(body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      const response = result.response.response;

      //  mfa
      if (response?.mfa) {
        refs.amaui.current = response.value;

        setStep(2);

        snackbars.add({
          primary: `Use your authenticator app, to confirm your sign in`
        });
      }
      else {
        snackbars.add({
          primary: 'Signed in succefully!'
        });

        await signInUser(response);
      }
    }

    setLoading(false);
  }, []);

  const getGoogleAccessToken = () => {
    return new Promise((resolve, reject) => {
      if (googleLibrary.oauth2) {
        googleLibrary.oauth2.callback = (response: any) => resolve(response);

        googleLibrary.oauth2.error_callback = (response: any) => resolve(response);

        googleLibrary.oauth2.requestAccessToken();
      }
    });
  };

  const onGoogleClick = React.useCallback(async () => {
    const response = await getGoogleAccessToken();

    onSignInGoogle(response);
  }, []);

  const googlePrompt = React.useCallback(() => {
    if ((window as any).google) {
      googleLibrary.callback = onSignInGoogle;

      (window as any).google.accounts?.id?.prompt();
    }
  }, []);

  const init = React.useCallback(() => {
    // Google sign in propmpt on init
    googlePrompt();
  }, []);

  React.useEffect(() => {
    // init
    init();
  }, []);

  const onSubmitSignIn = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    const valid = await formSignIn.validate();

    if (!valid) return;

    setLoading(true);

    const invite = UserService.invite || query.get('invite');

    const body: ISignIn = {
      ...(await getDeviceAndLocation()),

      ...refs.formSignIn.current.value
    };

    if (invite) body.invite = { id: invite?.id || invite };

    const result = await AuthService.signIn(body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      const response = result.response.response;

      //  mfa
      if (response?.mfa) {
        refs.amaui.current = response.value;

        setStep(2);

        snackbars.add({
          primary: `Use your authenticator app, to confirm your sign in`
        });
      }
      else {
        await signInUser(response);

        snackbars.add({
          primary: 'Signed in succefully!'
        });
      }
    }

    setLoading(false);
  }, [formSignIn]);

  const onSubmitMFA = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    const valid = await formMFA.validate();

    if (!valid) return;

    setLoading(true);

    const body = {
      mfa: refs.formMFA.current.value.mfa,
      amaui: refs.amaui.current
    };

    const result = await AuthService.signInMFAapp(body);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      const response = result.response.response;

      await signInUser(response);

      snackbars.add({
        primary: 'Signed in succefully!'
      });
    }

    setLoading(false);
  }, [formMFA]);

  const steps: any = {
    1: <>
      <Type
        version='h3'
      >
        Sign in
      </Type>

      <Line
        gap={3}

        fullWidth
      >
        <Button
          color='themed'

          version='filled'

          fullWidth

          onClick={onGoogleClick}

          className={classes.buttonGoogle}

          disabled={loading}
        >
          <IconGoogleSymbol
            className={classes.iconGoogle}
          />

          <Type
            version='t3'
          >
            Sign in with Google
          </Type>
        </Button>

        <Divider>
          or
        </Divider>

        <Form
          onSubmit={onSubmitSignIn}
        >
          <FormRow
            fullWidth
          >
            <TextField
              name='Email'

              type='email'

              valueDefault={formSignIn.values['user.email'].value}

              error={formSignIn.values['user.email'].error}

              helperText={formSignIn.values['user.email'].error}

              onChange={(valueNew: any) => formSignIn.onChange('user.email', valueNew, undefined, { rerenderOnUpdate: false })}

              fullWidth

              disabled={!!UserService.invite}
            />

            <TextField
              name='Password'

              type='password'

              valueDefault={formSignIn.values['user.settings.security.password'].value}

              error={formSignIn.values['user.settings.security.password'].error}

              helperText={formSignIn.values['user.settings.security.password'].error}

              onChange={(valueNew: any) => formSignIn.onChange('user.settings.security.password', valueNew, undefined, { rerenderOnUpdate: false })}

              footer={(
                <Line
                  direction='row'

                  justify='flex-end'

                  style={{
                    marginTop: 10
                  }}
                >
                  <Link
                    tonal
                    version='b2'

                    tabIndex={0}

                    onClick={() => navigate('/forgot-password')}
                  >
                    Forgot your password?
                  </Link>
                </Line>
              )}

              fullWidth
            />
          </FormRow>

          <Line
            direction='row'

            align='center'

            justify='space-between'

            fullWidth

            className={classes.buttons}
          >
            <Button
              version='text'

              onClick={() => navigate('/sign-up')}
            >
              Sign up
            </Button>

            <Button
              type='submit'

              // onClick={() => setStep(2)}

              disabled={loading}
            >
              Next
            </Button>
          </Line>
        </Form>
      </Line>
    </>,

    2: <>
      <Line
        gap={3}

        direction='column'

        align='center'
      >
        <Type
          version='h3'
        >
          Sign in
        </Type>

        <Line
          gap={1}

          direction='column'

          align='center'
        >
          <Type
            version='t1'
          >
            Authentication code
          </Type>

          <Type
            version='b2'

            style={{
              textAlign: 'center'
            }}
          >
            Enter the code from your authentication app.
          </Type>
        </Line>
      </Line>

      <Line
        gap={3}

        fullWidth
      >
        <Form
          onSubmit={onSubmitMFA}
        >
          <TextField
            key='mfa'

            name='Authentication code'

            valueDefault={formMFA.values['mfa'].value}

            error={formMFA.values['mfa'].error}

            helperText={formMFA.values['mfa'].error}

            onChange={(valueNew: any) => formMFA.onChange('mfa', valueNew, undefined, { rerenderOnUpdate: false })}

            fullWidth
          />

          <Line
            direction='row'

            align='center'

            justify='space-between'

            fullWidth

            className={classes.buttons}
          >
            <Button
              version='text'

              onClick={() => setStep(1)}
            >
              Back
            </Button>

            <Button
              type='submit'

              disabled={loading}
            >
              Next
            </Button>
          </Line>
        </Form>
      </Line>
    </>
  };

  return (
    <Line
      ref={ref}

      gap={4}

      direction='column'

      align='center'

      fullWidth

      className={classNames([
        className,
        classes.root,
        'amaui-app-SignIn'
      ])}

      {...other}
    >
      {steps[step]}
    </Line>
  );
});

export default SignIn;
