import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Form, FormRow, Line, Type, useForm, useSnackbars } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { ValidationError } from '@amaui/errors';

import { Button, TextField } from 'ui';
import { AuthService } from 'services';
import { getErrorMessage } from 'utils';

const useStyle = style(theme => ({
  root: {},

  buttons: {
    marginTop: 30
  }
}), { name: 'amaui-app-route-ForgotPassword' });

const ForgotPassword = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const navigate = useNavigate();
  const snackbars = useSnackbars();

  const [step, setStep] = React.useState(1);
  const [loading, setLoading] = React.useState(false);

  const formEmail = useForm({
    values: {
      'email': {
        name: 'email',
        isValid: 'email',
        required: true
      }
    }
  });

  const formOTP = useForm({
    values: {
      'otp': {
        name: 'OTP',
        required: true,
        capitalize: false
      }
    }
  });

  const formPassword = useForm({
    values: {
      'password': {
        name: 'password',
        isValid: 'password',
        messages: {
          isValid: 'Password must have min 7 characters, with at least one lowercase, uppercase and a number character'
        },
        required: true
      },
      'password_confirm': {
        name: 'password confirm',
        required: true,
        method: [
          (value, { form }) => {
            const password = form.values['password'];

            if (value !== password.value) throw new ValidationError(`Password confirm has to match the password`);
          }
        ]
      }
    }
  });

  const refs = {
    token: React.useRef(),
    formEmail: React.useRef(formEmail),
    formOTP: React.useRef(formOTP),
    formPassword: React.useRef(formPassword)
  };

  refs.formEmail.current = formEmail;

  refs.formOTP.current = formOTP;

  refs.formPassword.current = formPassword;

  const onSubmitEmail = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    const valid = await formEmail.validate();

    if (!valid) return;

    setLoading(true);

    const result = await AuthService.forgotPassword({
      email: refs.formEmail.current.value.email
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      setStep(2);

      snackbars.add({
        primary: result.response?.response
      });
    }

    setLoading(false);
  }, [formEmail]);

  const onSubmitOTP = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    let valid = await formEmail.validate();

    if (!valid) return;

    valid = await formOTP.validate();

    if (!valid) return;

    setLoading(true);

    const result = await AuthService.forgotPasswordOTP({
      email: refs.formEmail.current.value.email,
      otp: refs.formOTP.current.value.otp
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      setStep(3);

      refs.token.current = result.response.response;

      snackbars.add({
        primary: `One time password validated`
      });
    }

    setLoading(false);
  }, [formEmail, formOTP]);

  const onSubmitPassword = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    let valid = await formEmail.validate();

    if (!valid) return;

    valid = await formPassword.validate();

    if (!valid) return;

    setLoading(true);

    const result = await AuthService.forgotPasswordUpdate({
      user: {
        email: refs.formEmail.current.value.email,

        settings: {
          security: {
            password: refs.formPassword.current.value.password
          }
        }
      },

      token: refs.token.current as any
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: result.response?.response
      });

      navigate('/sign-in');
    }

    setLoading(false);
  }, [formEmail, formPassword]);

  const steps: any = {
    1: <>
      <Line
        gap={3}

        direction='column'

        align='center'
      >
        <Type
          version='h3'
        >
          Forgot password
        </Type>

        <Line
          gap={1}

          direction='column'

          align='center'
        >
          <Type
            version='t1'
          >
            Email
          </Type>

          <Type
            version='b2'

            style={{
              textAlign: 'center'
            }}
          >
            Provide us with your email, and we will send you a one time password to your email, enter it in the next step.
          </Type>
        </Line>
      </Line>

      <Line
        gap={3}

        fullWidth
      >
        <Form
          onSubmit={onSubmitEmail}
        >
          <TextField
            key='email'

            name='Email'

            type='email'

            valueDefault={formEmail.values['email'].value}

            error={formEmail.values['email'].error}

            helperText={formEmail.values['email'].error}

            onChange={(valueNew: any) => formEmail.onChange('email', valueNew)}

            fullWidth
          />

          <Line
            direction='row'

            align='center'

            justify='space-between'

            fullWidth

            className={classes.buttons}
          >
            <Button
              version='text'

              onClick={() => navigate('/sign-in')}
            >
              Sign in
            </Button>

            <Button
              type='submit'

              disabled={loading}
            >
              Next
            </Button>
          </Line>
        </Form>
      </Line>
    </>,

    2: <>
      <Line
        gap={3}

        direction='column'

        align='center'
      >
        <Type
          version='h3'
        >
          Forgot password
        </Type>

        <Line
          gap={1}

          direction='column'

          align='center'
        >
          <Type
            version='t1'
          >
            One time password
          </Type>

          <Type
            version='b2'

            style={{
              textAlign: 'center'
            }}
          >
            Enter the one time password we sent to your email, in the input. Once the code you enter is valid, you will be able to update your password in the next step.
          </Type>
        </Line>
      </Line>

      <Line
        gap={3}

        fullWidth
      >
        <Form
          onSubmit={onSubmitOTP}
        >
          <TextField
            key='otp'

            name='One time password'

            valueDefault={formOTP.values['otp'].value}

            error={formOTP.values['otp'].error}

            helperText={formOTP.values['otp'].error}

            onChange={(valueNew: any) => formOTP.onChange('otp', valueNew)}

            fullWidth
          />

          <Line
            direction='row'

            align='center'

            justify='space-between'

            fullWidth

            className={classes.buttons}
          >
            <Button
              version='text'

              onClick={() => setStep(1)}
            >
              Back
            </Button>

            <Button
              type='submit'

              disabled={loading}
            >
              Next
            </Button>
          </Line>
        </Form>
      </Line>
    </>,

    3: <>
      <Line
        gap={3}

        direction='column'

        align='center'
      >
        <Type
          version='h3'
        >
          Forgot password
        </Type>

        <Line
          gap={1}

          direction='column'

          align='center'
        >
          <Type
            version='t1'
          >
            New password
          </Type>

          <Type
            version='b2'

            style={{
              textAlign: 'center'
            }}
          >
            Enter the new password, to update it.
          </Type>
        </Line>
      </Line>

      <Line
        gap={3}

        fullWidth
      >
        <Form
          onSubmit={onSubmitPassword}
        >
          <FormRow
            fullWidth
          >
            <TextField
              key='password'

              name='Password'

              type='password'

              valueDefault={formPassword.values['password'].value}

              error={formPassword.values['password'].error}

              helperText={formPassword.values['password'].error}

              onChange={(valueNew: any) => formPassword.onChange('password', valueNew)}

              fullWidth
            />

            <TextField
              key='password_confirm'

              name='Password Confirm'

              type='password'

              valueDefault={formPassword.values['password_confirm'].value}

              error={formPassword.values['password_confirm'].error}

              helperText={formPassword.values['password_confirm'].error}

              onChange={(valueNew: any) => formPassword.onChange('password_confirm', valueNew)}

              fullWidth
            />
          </FormRow>

          <Line
            direction='row'

            align='center'

            justify='space-between'

            fullWidth

            className={classes.buttons}
          >
            <Button
              version='text'

              onClick={() => {
                setStep(2);

                formOTP.clear();
              }}
            >
              Back
            </Button>

            <Button
              type='submit'

              disabled={loading}
            >
              Next
            </Button>
          </Line>
        </Form>
      </Line>
    </>
  };

  return (
    <Line
      ref={ref}

      gap={4}

      direction='column'

      align='center'

      flex

      fullWidth

      className={classNames([
        className,
        classes.root,
        'amaui-app-ForgotPassword'
      ])}

      {...other}
    >
      {steps[step]}
    </Line>
  );
});

export default ForgotPassword;
