import { Chat, Message } from '@amaui/api-utils';
import AmauiSubscription from '@amaui/subscription';
import { IAddMany, IAdditional, IRemoveMany, IResponse, IUpdateMany } from '@amaui/sdk/other';
import { Query } from '@amaui/models';

import { BaseService } from './base';
import { AmauiService } from 'services';
import { IQuerySubscription, ISelectedSubscription } from 'types';

class ChatService extends BaseService<Chat> {
  public queryMessages = new AmauiSubscription<IQuerySubscription>();
  public selectedMessages = new AmauiSubscription<ISelectedSubscription>();

  get paginationLimit() {
    return 'all';
  }

  public constructor() {
    super('chats');

    this.queryObjectsInit(this.queryMessages, 'queryMessagePost');

    this.selectedObjectsInit(this.selectedMessages);
  }

  public async claim(id: string, options?: IAdditional) {
    return AmauiService.sdk[this.route as 'chats']?.claim(id, options);
  }

  // messages
  public async addMessage(id: string, body: Partial<Message>, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.chats.addMessage(id, body as any, options);
  }

  public async addManyMessage(id: string, body: IAddMany<Message>, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.chats.addManyMessage(id, body as any, options);
  }

  public async updateMessage(id: string, id_message: string, body: Message, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.chats.updateMessage(id, id_message, body as any, options);
  }

  public async updateManyMessage(id: string, body: IUpdateMany<Message>, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.chats.updateManyMessage(id, body as any, options);
  }

  public async removeMessage(id: string, id_message: string, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.chats.removeMessage(id, id_message, options);
  }

  public async removeManyMessage(id: string, body: IRemoveMany<Message>, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.chats.removeManyMessage(id, body as any, options);
  }

  public async getMessage(id: string, id_message: string, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.chats.getMessage(id, id_message, options);
  }

  public async queryMessage(id: string, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.chats.queryMessage(id, options);
  }

  public async queryMessagePost(id: string, body?: Partial<Query>, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.chats.queryMessagePost(id, body, options);
  }

}

export default new ChatService();
