import React from 'react';

import { is } from '@amaui/utils';
import { Select } from '@amaui/ui-react';
import { ISelect } from '@amaui/ui-react/Select/Select';
import { classNames, style } from '@amaui/style-react';

import IconMaterialCloseW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialCloseW100';
import IconMaterialArrowDropDownW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialArrowDropDownW100';

const useStyle = style(theme => ({
  root: {
    '& .amaui-Select-input': {
      whiteSpace: 'nowrap',
      overflow: 'auto hidden',

      '&::-webkit-scrollbar': {
        height: 0,
        width: 0
      }
    }
  }
}), { name: 'amaui-app-Select' });

const Element: React.FC<ISelect> = React.forwardRef((props, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle(props);

  const [width, setWidth] = React.useState();

  const refs = {
    root: React.useRef<HTMLElement>(),
    width: React.useRef(width)
  };

  refs.width.current = width;

  return (
    <Select
      ref={(item: any) => {
        if (ref) {
          if (is('function', ref)) ref(item);
          ref.current = item;
        }

        refs.root.current = item;

        if (refs.width.current === undefined) {
          setTimeout(() => {
            setWidth(item?.clientWidth);
          }, 140);
        }
      }}

      version='outlined'

      size='small'

      IconClear={IconMaterialCloseW100Rounded}

      IconDropdown={IconMaterialArrowDropDownW100Rounded}

      IconButtonProps={{
        size: 'regular'
      }}

      IconProps={{
        size: 'large'
      }}

      MenuProps={{
        portal: true,

        size: 'regular',

        style: {
          width
        }
      }}

      className={classNames([
        className,
        classes.root
      ])}

      clear={false}

      {...other}

      WrapperProps={{
        ...other.WrapperProps,

        className: classNames([
          other.WrapperProps?.className,

          classes.wrapper
        ])
      }}
    />
  );
});

export default Element;
