import React from 'react';
import { Outlet } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { Line, SectionReviews, Type } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';

import { ReactComponent as IconLogo } from 'assets/svg/logo.svg';

import { Meta, useLibrary } from 'ui';
import { UserService } from 'services';
import { googleLibrary } from 'utils';
import config from 'config';

const useStyle = style(theme => ({
  root: {
    padding: '40px 24px',
    backgroundImage: `url('/assets/images/background.jpg')`,
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    overflow: 'hidden auto',

    '& .amaui-SectionReviews-review': {
      boxShadow: theme.shadows.values.default[1]
    }
  },

  header: {

  },

  logo: {
    height: 55,
    width: 'auto',
    cursor: 'pointer',
    userSelect: 'none',
    transition: theme.methods.transitions.make('transform', { duration: 'xs' }),

    '&:active': {
      transform: 'scale(0.94)'
    }
  },

  main: {
    maxWidth: 440,
    backdropFilter: 'blur(4px)',
    background: theme.methods.palette.color.colorToRgb(theme.palette.background.primary.secondary, theme.palette.light ? 94 : 84),
    padding: 40,
    borderRadius: 40,
    overflow: 'hidden'
  },

  mainPre: {
    maxWidth: 440,
    backdropFilter: 'blur(4px)',
    background: theme.methods.palette.color.colorToRgb(theme.palette.background.primary.secondary, theme.palette.light ? 94 : 84),
    padding: '20px 40px',
    borderRadius: 40,
    overflow: 'hidden'
  },

  inviteLogo: {
    height: 40,
    width: 'auto'
  },

  footer: {
    marginTop: 44,
    backdropFilter: 'blur(4px)',
    background: theme.methods.palette.color.colorToRgb(theme.palette.background.primary.secondary, theme.palette.light ? 94 : 84),
    padding: '40px 40px 28px',
    maxWidth: '100%',
    borderRadius: 40,
    overflow: 'hidden'
  }
}), { name: 'amaui-app-route-Root-Auth' });

const reviews = [
  { name: 'Ana Belua', meta: 'Marketing Manager', rating: 5, image: 'https://picsum.photos/seed/ana/70/70', description: `I must say, I love it!` },
  { name: 'Ina Lorum', meta: 'Personal English Teacher', rating: 5, image: 'https://picsum.photos/seed/ina/70/70', description: `Using it every day, so much productivity!` },
  { name: 'Iian Muy', meta: 'Business Owner', rating: 5, image: 'https://picsum.photos/seed/iian/70/70', description: `I must say, every day I love it more, and more.` },
  { name: 'Lorem Ipsum', meta: 'CEO of the Company', rating: 5, image: 'https://picsum.photos/seed/lorem/70/70', description: `Very useful for my business as a CEO.` },
];

const Root = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const navigate = useNavigate();
  const media = useLibrary();

  const [loaded, setLoaded] = React.useState(false);

  const googleCallback = React.useCallback((...args: any[]) => {
    if (typeof googleLibrary.callback === 'function') googleLibrary.callback(...args);
  }, []);

  const initGoogle = React.useCallback(() => {
    // Google
    if ((window as any).google?.accounts) {
      // api new
      (window as any).google.accounts.id.initialize({
        client_id: config.value.services.google.client_id,
        callback: googleCallback
      });

      // oauth2
      googleLibrary.oauth2 = (window as any).google.accounts.oauth2.initTokenClient({
        client_id: config.value.services.google.client_id,
        scope: 'profile email'
      });
    }
  }, []);

  const init = React.useCallback(() => {
    // init google
    initGoogle();
  }, []);

  React.useEffect(() => {
    // init
    init();

    // only is some use cases 
    media.close();

    setLoaded(true);
  }, []);

  if (!loaded) return null;

  const invite = UserService.invite;

  return <>
    <Meta
      app='amaui'
    />

    <div
      ref={ref}

      className={classNames([
        className,
        classes.root,
        'amaui-app-Root-Auth'
      ])}

      {...other}
    >
      <Line
        gap={3}

        direction='column'

        align='center'

        justify='center'

        flex

        fullWidth
      >
        <Line
          direction='row'

          justify='center'

          className={classes.header}
        >
          <IconLogo
            className={classes.logo}

            onClick={() => navigate('/sign-in')}
          />
        </Line>

        {invite && (
          <Line
            gap={1}

            direction='column'

            align='center'

            fullWidth

            className={classes.mainPre}
          >
            <Type
              version='b1'
            >
              Invited by
            </Type>

            <Line
              gap={1.5}

              direction='row'

              align='center'
            >
              {invite.organization.logo && (
                <img
                  alt=''

                  src={invite.organization.logo?.url}

                  className={classes.inviteLogo}
                />
              )}

              <Type
                version='t1'

                align='center'
              >
                {invite.organization.name}
              </Type>
            </Line>
          </Line>
        )}

        <Line
          gap={0}

          direction='column'

          fullWidth

          className={classes.main}
        >
          <Outlet />
        </Line>

        <Line
          gap={3}

          align='center'

          className={classes.footer}
        >
          <SectionReviews
            padding={false}

            values={reviews}
          />
        </Line>
      </Line>
    </div>
  </>;
});

export default Root;
