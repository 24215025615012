import { Task } from '@amaui/api-utils';
import AmauiSubscription from '@amaui/subscription';

import { BaseService } from './base';

class TaskService extends BaseService<Task> {
  public task = new AmauiSubscription<Task>();

  public get paginationLimit() { return 20; }

  public constructor() {
    super('tasks');
  }

}

export default new TaskService();
