import { SignIn } from '@amaui/api-utils';
import { IAdditional } from '@amaui/sdk/other';

import { BaseService } from './base';
import { AmauiService, AuthService } from 'services';

class SignInService extends BaseService<SignIn> {

  public constructor() {
    super('signIns');
  }

  public async inactivateAll(options?: IAdditional) {
    return AmauiService.sdk[this.route as 'signIns']?.inactivateAll(options);
  }

  public async removeAll(options?: IAdditional) {
    const response = await AmauiService.sdk[this.route as 'signIns']?.removeAll(options);

    AuthService.me();

    return response;
  }

}

export default new SignInService();
