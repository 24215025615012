import { UserGroup } from '@amaui/api-utils';

import { BaseService } from './base';

class UserGroupService extends BaseService<UserGroup> {

  get paginationLimit() {
    return 'all';
  }

  public constructor() {
    super('userGroups');
  }

}

export default new UserGroupService();
