import React from 'react';

import { capitalize, is, setObjectValue } from '@amaui/utils';
import { Buttons, Checkbox, Chip, Label, Line, PaginationItem, Switch, Tooltip, Type } from '@amaui/ui-react';
import { add, AmauiDate } from '@amaui/date';

import { Button, DatePicker, Input, NumericTextField, Select } from '.';

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    form,

    object,

    property = 'repeat',

    onUpdate,

    onClose,

    ...other
  } = props;

  const [repeat, setRepeat] = React.useState(object?.repeat || form?.value?.repeat || {});

  const refs = {
    repeat: React.useRef(repeat)
  };

  refs.repeat.current = repeat;

  React.useEffect(() => {
    if (form && form.value.repeat && form.value.repeat !== repeat) setRepeat(form.value.repeat);
  }, [form]);

  const update = React.useCallback((...args: any) => {
    if (form) form.onChange(...args);
    else if (object && onUpdate) {
      const repeatNew = { ...refs.repeat.current };
      const items = is('array', args[0]) ? args[0] : [args];

      items.forEach(item => {
        setObjectValue(repeatNew, item[2], item[1]);
      });

      setRepeat(repeatNew);
    }
  }, [object, onUpdate, form]);

  const onConfirm = React.useCallback(() => {
    onUpdate(property, refs.repeat.current);

    if (is('function', onClose)) onClose();
  }, [property, onClose]);

  const onWeekdayToggle = React.useCallback((dayWeekValue: number) => {
    const weekdays = [...repeat?.weekdays || []];

    const index = weekdays.findIndex((item: any) => item === dayWeekValue);

    index > -1 ? weekdays.splice(index, 1) : weekdays.push(dayWeekValue);

    update(property, weekdays, 'weekdays');
  }, [form, repeat, property]);

  const optionsPremade = React.useMemo(() => {
    return [
      { name: 'Daily', unit: 'day', value: 1 },
      { name: 'Weekly', unit: 'week', value: 1 },
      { name: 'Monthly', unit: 'month', value: 1 },
      { name: 'Yearly', unit: 'year', value: 1 }
    ];
  }, []);

  const optionsUnit = React.useMemo(() => {
    return ['day', 'week', 'month', 'year'].map(item => ({
      name: capitalize(item),
      value: item
    }));
  }, []);

  const optionsWeekDays = React.useMemo(() => {
    return [
      { name: 'Monday', value: 1 },
      { name: 'Tuesday', value: 2 },
      { name: 'Wednesday', value: 3 },
      { name: 'Thursday', value: 4 },
      { name: 'Friday', value: 5 },
      { name: 'Saturday', value: 6 },
      { name: 'Sunday', value: 0 }
    ];
  }, []);

  const optionsEndBy = React.useMemo(() => {
    return [
      { name: 'Date', version: 'date', value: add(1, 'month').milliseconds },
      { name: 'Count', version: 'count', value: 1 }
    ];
  }, []);

  const [custom, setCustom] = React.useState((
    (repeat?.active && repeat?.value > 1) ||
    (
      repeat?.skip_weekends ||
      !!repeat?.weekdays?.length
    )
  ));

  const countStyle = {
    width: 175
  };

  return (
    <Input
      ref={ref}

      name='Repeat'

      startName={(
        <Switch
          value={repeat?.active}

          onChange={(valueNew: any) => update(property, valueNew, 'active')}
        />
      )}

      footer={(object && onUpdate) && (
        <Line
          gap={1}

          direction='row'

          align='center'

          justify='space-between'

          fullWidth
        >
          {onClose ? (
            <Button
              onClick={onClose}

              version='text'
            >
              Close
            </Button>
          ) : <div />}

          <Button
            onClick={onConfirm}
          >
            Save
          </Button>
        </Line>
      )}

      {...other}
    >
      <Line
        gap={1.5}

        fullWidth
      >
        <Buttons
          value={`${repeat.unit}-${repeat.value}`}

          orientation={{
            default: 'horizontal',
            xxs: 'vertical',
            xs: 'vertical',
            sm: 'vertical'
          }}
        >
          {optionsPremade.map((item, index) => (
            <Chip
              key={index}

              value={`${item.unit}-${item.value}`}

              onClick={() => {
                update([
                  [property, true, 'active'],
                  [property, item.value, 'value'],
                  [property, item.unit, 'unit']
                ]);

                setCustom(false);
              }}
            >
              {item.name}
            </Chip>
          ))}

          <Chip
            onClick={() => {
              setCustom(item => !item);

              update([
                [property, repeat.unit || 'day', 'unit'],
                [property, repeat.value || 1, 'value']
              ]);
            }}

            selected={custom}
          >
            Custom
          </Chip>
        </Buttons>

        {custom && (
          <Line
            gap={2}

            fullWidth
          >
            <Line
              gap={1.5}

              direction='row'

              align='center'

              wrap='wrap'
            >
              <Input
                name='Unit'

                size='small'

                fullWidth={false}
              >
                <Select
                  value={repeat.unit || 'day'}

                  onChange={(valueNew: string) => update(property, valueNew, 'unit')}

                  options={optionsUnit}

                  size='small'
                />
              </Input>

              <Input
                name='How many'

                size='small'

                fullWidth={false}
              >
                <NumericTextField
                  value={repeat.value}

                  onChange={(valueNew: string) => update(property, valueNew || 1, 'value')}

                  min={1}

                  size='small'

                  style={countStyle}
                />
              </Input>
            </Line>

            {repeat.unit === 'day' && (
              <Label
                checked={!!repeat?.skip_weekends}

                onChange={(valueNew: any) => update(property, valueNew, 'skip_weekends')}

                color='default'
              >
                <Checkbox />

                <Type
                  version='b1'
                >
                  Skip weekends
                </Type>
              </Label>
            )}

            {repeat.unit === 'week' && (
              <Input
                name='Weekdays'

                description='Repeat on which days of the week'

                size='small'

                fullWidth={false}
              >
                <Line
                  gap={0.5}

                  direction='row'

                  align='center'

                  fullWidth
                >
                  {optionsWeekDays.map((item: any, index: number) => (
                    <Tooltip
                      name={item.name}
                    >
                      <PaginationItem
                        key={index}

                        selected={!!repeat?.weekdays?.includes(item.value)}

                        onClick={() => onWeekdayToggle(item.value)}

                        size='small'
                      >
                        {item.name[0]}
                      </PaginationItem>
                    </Tooltip>
                  ))}
                </Line>
              </Input>
            )}

            <Input
              ref={ref}

              startName={(
                <Switch
                  value={!!repeat?.ends?.active}

                  onChange={(valueNew: any) => update(property, valueNew, 'ends.active')}

                  size='small'
                />
              )}

              name='End repeat'

              description='End repeat after date or some number of repeats'

              size='small'
            >
              {repeat.ends?.active && <>
                <Buttons
                  value={repeat.ends?.version}

                  size='small'
                >
                  {optionsEndBy.map((item, index) => (
                    <Chip
                      key={index}

                      value={item.version}

                      onClick={() => {
                        update([
                          [property, true, 'ends.active'],
                          [property, item.version, 'ends.version'],
                          [property, item.value, 'ends.value']
                        ]);
                      }}
                    >
                      {item.name}
                    </Chip>
                  ))}
                </Buttons>

                {repeat.ends?.version === 'count' && (
                  <NumericTextField
                    key='count'

                    name='Count'

                    value={repeat.ends?.value}

                    onChange={(valueNew: any) => update(property, valueNew || 1, 'ends.value')}

                    min={1}

                    size='small'

                    style={countStyle}
                  />
                )}

                {repeat.ends?.version === 'date' && (
                  <DatePicker
                    name='Date'

                    value={repeat.ends?.value ? new AmauiDate(repeat.ends.value) : undefined}

                    onChange={(valueNew: any) => update(property, valueNew.milliseconds, 'ends.value')}

                    size='small'

                    style={countStyle}
                  />
                )}
              </>}
            </Input>
          </Line>
        )}
      </Line>
    </Input>
  );
});

export default Element;
