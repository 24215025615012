import { Socket, io } from 'socket.io-client';

import { is, unique } from '@amaui/utils';
import AmauiSubscription from '@amaui/subscription';
import { Chat, IMessageReaction, Message, Website } from '@amaui/api-utils';
import { IResponse } from '@amaui/sdk/other';

import config from 'config';
import { LogService } from 'services';

export class SocketService {
  public client: Socket = undefined as any;
  // values
  public online = new AmauiSubscription<string[]>([]);
  // chat
  public chatAdd = new AmauiSubscription<Chat>();
  public chatUpdate = new AmauiSubscription<Chat>();
  public chatArchive = new AmauiSubscription<Chat>();
  public chatRemove = new AmauiSubscription<Chat>();
  // message
  public messageAdd = new AmauiSubscription<Message>();
  public messageRemove = new AmauiSubscription<Message>();
  // message reactions
  public messageReactionAdd = new AmauiSubscription<Message>();
  public messageReactionRemove = new AmauiSubscription<Message>();
  // website
  // chat
  // update
  public websiteChatUpdate = new AmauiSubscription<Website>();
  public websiteChatClaim = new AmauiSubscription<Chat>();

  public init() {
    this.client = io(config.value.apps.sockets.url, {
      extraHeaders: {

      },
      auth(callback) {
        callback({ token: `amaui ${config.value.amaui_token}` });
      },
      transports: ['websocket']
    });

    // events
    this.client.on('error', (...args) => {
      LogService.debug('Socket error', ...args);
    });

    this.client.on('connect', (...args) => {
      LogService.debug('Socket connected', this.client.connected, ...args);
    });

    this.client.on('disconnect', (...args) => {
      LogService.debug('Socket disconnected', this.client.connected, ...args);
    });

    this.client.on('reconnect', (...args) => {
      LogService.debug('Socket reconnected', this.client.connected, ...args);
    });

    // api
    this.client.on('add-chat', (chat: Chat) => {
      LogService.debug('Socket event: add-chat', chat);

      this.update(chat, 'add-chat');
    });

    this.client.on('update-chat', (chat: Chat) => {
      LogService.debug('Socket event: update-chat', chat);

      this.update(chat, 'update-chat');
    });

    this.client.on('archive-chat', (chat: Chat) => {
      LogService.debug('Socket event: archive-chat', chat);

      this.update(chat, 'archive-chat');
    });

    this.client.on('remove-chat', (chat: Chat) => {
      LogService.debug('Socket event: remove-chat', chat);

      this.update(chat, 'remove-chat');
    });

    this.client.on('add-message', (message: Message) => {
      LogService.debug('Socket event: add-message', message);

      this.update(message, 'add-message');
    });

    this.client.on('remove-message', (message: Message) => {
      LogService.debug('Socket event: remove-message', message);

      this.update(message, 'remove-message');
    });

    this.client.on('add-message-reaction', (message: Message) => {
      LogService.debug('Socket event: add-message-reaction', message);

      this.update(message, 'add-message-reaction');
    });

    this.client.on('remove-message-reaction', (message: Message) => {
      LogService.debug('Socket event: remove-message-reaction', message);

      this.update(message, 'remove-message-reaction');
    });

    // website chat update
    this.client.on('website-chat-update', (website: Partial<Website>) => {
      LogService.debug('Socket event: website-chat-update', website);

      this.update(website, 'website-chat-update');
    });

    // value
    this.client.on('value', (valueNew: any) => {
      LogService.debug('Socket event: value', valueNew);

      this.update(valueNew?.value, valueNew?.version, true);
    });

    // online
    this.client.on('online', (valueNew: any) => {
      LogService.debug('Socket event: online', valueNew);

      this.update(valueNew?.value, 'online');
    });

    // offline
    this.client.on('offline', (valueNew: any) => {
      LogService.debug('Socket event: offline', valueNew);

      this.update(valueNew?.value, 'online', false);
    });
  }

  // chat
  public async addChat(chat: Partial<Chat>) {
    const response = await this.emit('add-chat', { chat });

    return response;
  }

  public async updateChat(chat: Partial<Chat>, body: any) {
    const response = await this.emit('update-chat', { chat, body });

    return response;
  }

  public async archiveChat(chat: Partial<Chat>, body: any) {
    const response = await this.emit('archive-chat', { chat, body });

    return response;
  }

  public async removeChat(chat: Partial<Chat>, body: any) {
    const response = await this.emit('remove-chat', { chat, body });

    return response;
  }

  // message
  public async addMessage(chat: Partial<Chat>, message: Partial<Message>) {
    const response = await this.emit('add-message', { chat, message });

    return response;
  }

  public async removeMessage(chat: Partial<Chat>, message: Partial<Message>) {
    const response = await this.emit('remove-message', { chat, message });

    return response;
  }

  // message reaction
  public async addMessageReaction(chat: Partial<Chat>, message: Partial<Message>, reaction: Partial<IMessageReaction>) {
    const response = await this.emit('add-message-reaction', { chat, message, reaction });

    return response;
  }

  public async removeMessageReaction(chat: Partial<Chat>, message: Partial<Message>, reaction: Partial<IMessageReaction>) {
    const response = await this.emit('remove-message-reaction', { chat, message, reaction });

    return response;
  }

  // website
  // website chat update
  public async updateWebsiteChat(website: Partial<Website>) {
    const response = await this.emit('website-chat-update', { website });

    return response;
  }

  // chat claim
  public async updateWebsiteClaim(chat: Partial<Chat>) {
    const response = await this.emit('website-chat-claim', { chat });

    return response;
  }

  // methods
  public update(value: any, version: string = 'online', add = true, all = false) {
    if (version === 'add-chat') this.chatAdd.emit(value);

    if (version === 'update-chat') this.chatUpdate.emit(value);

    if (version === 'archive-chat') this.chatArchive.emit(value);

    if (version === 'remove-chat') this.chatRemove.emit(value);

    if (version === 'add-message') this.messageAdd.emit(value);

    if (version === 'remove-message') this.messageRemove.emit(value);

    if (version === 'add-message-reaction') this.messageReactionAdd.emit(value);

    if (version === 'remove-message-reaction') this.messageReactionRemove.emit(value);

    if (version === 'website-chat-update') this.websiteChatUpdate.emit(value);

    if (version === 'website-chat-claim') this.websiteChatClaim.emit(value);

    if (version === 'online') {
      const valueNew = is('array', value) ? value : [value];

      let valueOnline = [...this.online.value!];

      if (add) {
        if (all) valueOnline = valueNew;
        else valueOnline = unique([...valueOnline, ...valueNew]);
      }
      else {
        if (all) valueOnline = [];
        else {
          valueNew.forEach((itemNew: string) => {
            const index = valueOnline.findIndex((item: any) => item === itemNew);

            if (index > -1) valueOnline.splice(index, 1);
          });
        }
      }

      this.online.emit(valueOnline);
    }
  }

  public async emit(version: string, data: any): Promise<IResponse> {
    const result: any = await new Promise((resolve, reject) => {
      this.client.emit(version, data, resolve);
    });

    const response: IResponse = {
      status: result?.meta?.status,
      response: result
    };

    return response;
  }
}

export default new SocketService();
