import React from 'react';

import { AutoCompleteCurrency } from '@amaui/ui-react'
import { IAutoCompleteCurrency } from '@amaui/ui-react/AutoCompleteCurrency/AutoCompleteCurrency';

import IconMaterialCloseRounded from '@amaui/icons-material-rounded-react/IconMaterialCloseW100';
import IconMaterialArrowDropDownRounded from '@amaui/icons-material-rounded-react/IconMaterialArrowDropDownW100';

const Element: React.FC<IAutoCompleteCurrency> = props => {

  return (
    <AutoCompleteCurrency
      IconClear={IconMaterialCloseRounded}

      IconDropdown={IconMaterialArrowDropDownRounded}

      IconButtonProps={{
        size: 'regular'
      }}

      IconProps={{
        size: 'large'
      }}

      clear={false}

      {...props}
    />
  );
};

export default Element;
