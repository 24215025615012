import React from 'react';

import { DateRangePicker } from '@amaui/ui-react';
import { IDateRangePicker } from '@amaui/ui-react/DateRangePicker/DateRangePicker';

import IconMaterialEditW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialEditW100';
import IconMaterialCalendarTodayW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialCalendarTodayW100';
import IconMaterialCloseW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialCloseW100';

const Element: React.FC<IDateRangePicker> = React.forwardRef((props, ref: any) => {
  return (
    <DateRangePicker
      ref={ref}

      color='primary'

      size='small'

      Icon={IconMaterialCalendarTodayW100Rounded}

      IconEnter={IconMaterialEditW100Rounded}

      IconClose={IconMaterialCloseW100Rounded}

      IconProps={{
        size: 'regular'
      }}

      {...props}
    />
  );
});

export default Element;
