import React from 'react';

import { SpeechToText } from '@amaui/ui-react'
import { ISpeechToText } from '@amaui/ui-react/SpeechToText/SpeechToText';

import IconMaterialSpeechToTextRounded from '@amaui/icons-material-rounded-react/IconMaterialSpeechToTextW100';
import IconMaterialStopRounded from '@amaui/icons-material-rounded-react/IconMaterialStopW100';

const Element: React.FC<ISpeechToText> = props => {

  return (
    <SpeechToText
      Icon={IconMaterialSpeechToTextRounded}

      IconStop={IconMaterialStopRounded}

      IconProps={{
        size: 'large'
      }}

      {...props}
    />
  );
};

export default Element;
