import React from 'react';

import { Form } from '@amaui/ui-react'
import { IForm } from '@amaui/ui-react/Form/Form';

import IconMaterialExpandMoreW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialExpandMoreW100';

const Element: React.FC<IForm> = props => {

  return (
    <Form
      AccordionProps={{
        ExpandIcon: IconMaterialExpandMoreW100Rounded
      }}

      {...props}
    />
  );
};

export default Element;
