import { User } from '@amaui/api-utils';
import { IAdditional, IResponse, IUserPush, IUserUpdate, IUserUpdatePassword, IUserUpdatePersonalization } from '@amaui/sdk/other';

import { AmauiService } from 'services';
import { BaseService } from './base';

class UserService extends BaseService<User> {
  public invite?: any;

  public constructor() {
    super('users');
  }

  public async getInvite(id: string, options?: IAdditional): Promise<IResponse> {
    return AmauiService.sdk.users.getInvite(id, options);
  }

  public async me(options?: IAdditional) {
    return AmauiService.sdk.users.me(options);
  }

  public async update(id: string, body: IUserUpdate, options?: IAdditional) {
    return AmauiService.sdk.users.update(id, body, options);
  }

  public async remove(options?: any) {
    return AmauiService.sdk.users.remove(options);
  }

  public async updatePersonalization(body: IUserUpdatePersonalization, options?: IAdditional) {
    return AmauiService.sdk.users.updatePersonalization(body, options);
  }

  public async mfaAppActivate(options?: IAdditional) {
    return AmauiService.sdk.users.mfaAppActivate(options);
  }

  public async mfaAppInactivate(options?: IAdditional) {
    return AmauiService.sdk.users.mfaAppInactivate(options);
  }

  public async updatePassword(body: IUserUpdatePassword, options?: IAdditional) {
    return AmauiService.sdk.users.updatePassword(body, options);
  }

  public async pushSubscribe(body: IUserPush, options?: IAdditional) {
    return AmauiService.sdk.users.pushSubscribe(body, options);
  }

  public async pushUnsubscribe(body: IUserPush, options?: IAdditional) {
    return AmauiService.sdk.users.pushUnsubscribe(body, options);
  }

}

export default new UserService();
