import React from 'react';

import { cleanValue } from '@amaui/utils';
import { useConfirm, useSnackbars, useSubscription } from '@amaui/ui-react';
import { IBaseElement } from '@amaui/ui-react/types';
import { IUser } from '@amaui/api-utils';

import IconMaterialPersonFilledW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialPersonFilledW100';
import IconMaterialCircleW100FilledRounded from '@amaui/icons-material-rounded-react/IconMaterialCircleW100Filled';

import { FormUserUpdate, ItemCard, ItemMenu } from 'ui';
import { AppService, AuthService, OrganizationService } from 'services';
import { getDate, getErrorMessage, getMediaUrl } from 'utils';
import { ISignedIn } from 'types';

export interface IEmployeeItem extends IBaseElement {
  object: IUser;
}

const Element: React.FC<IEmployeeItem> = React.forwardRef((props, ref: any) => {
  const {
    object,

    ...other
  } = props;

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const confirm = useConfirm();
  const snackbars = useSnackbars();

  const organization = signedIn?.organization;

  const userOrganization = object?.organizations?.find((item: any) => item.id === organization.id);

  const active = userOrganization?.active;
  const roles = userOrganization?.roles || ['user'];
  const status = userOrganization?.status || 'joined';

  const isInvited = status === 'invited';

  const isOwner = organization?.owner?.id === object.id;

  const isUser = signedIn?.user?.id === object.id;

  const onOpen = React.useCallback(() => {
    AppService.pages.add.emit({
      open: true,

      to: object.id,

      children: (
        <FormUserUpdate
          object={object}

          onConfirm={() => OrganizationService.queryUsers.value!.refetch()}
        />
      )
    });
  }, [object]);

  const onActive = React.useCallback(async () => {
    const confirmed = await confirm.open({
      name: `${active ? 'Inactivating' : 'Activating'} ${object.name} employee`
    });

    if (!confirmed) return;

    const result = await OrganizationService.updateUser(object.id, {
      active: !active
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `${object.name} employee ${active ? 'inactivated' : 'activated'}`
      });
    }

    // refresh 
    OrganizationService.queryUsers.value!.refetch();
  }, [object, active]);

  const onRemove = React.useCallback(async () => {
    const confirmed = await confirm.open({
      name: `Removing ${object.name} employee`
    });

    if (!confirmed) return;

    const result = await OrganizationService.removeUser(object.id);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `${object.name} employee removed`
      });
    }

    // refresh 
    OrganizationService.queryUsers.value!.refetch();
  }, [object, active]);

  const image = userOrganization?.personalization?.settings?.images?.profile;

  return (
    <ItemCard
      ref={ref}

      items_start={[
        {
          ...(image ? { url: getMediaUrl(image as any) } : { icon: IconMaterialPersonFilledW100Rounded }),

          ...((!(isUser || isOwner || isInvited) && {
            onClick: onOpen
          }))
        },
        {
          name: 'Name',
          value: object.name,

          ...((!(isUser || isOwner || isInvited) && {
            onClick: onOpen
          }))
        },
        {
          name: 'Email',
          value: object.email
        },
        {
          name: 'Roles',
          value: isOwner ? 'Owner' : roles.map((item: any) => cleanValue(item, { capitalize: true })).join(', ')
        },
        ...(!isInvited ? [
          {
            name: 'Access',
            value: active ? 'Active' : 'Inactive',
            propsValue: {
              color: active ? 'inherit' : 'error'
            }
          }] : []),
        {
          name: 'Status',
          value: cleanValue(status, { capitalize: true }),
          propsValue: {
            color: status === 'invited' ? 'info' : 'inherit'
          }
        }
      ]}

      items_end={[
        {
          name: isInvited ? 'Invited at' : 'Added at',
          value: getDate(userOrganization?.added_at!),
          align: 'flex-end',
          propsValue: {
            whiteSpace: 'nowrap'
          }
        },
        {
          value: !(isUser || isOwner) ? (
            <ItemMenu
              items={[
                ...(!isInvited ? [
                  { name: 'Open', onClick: onOpen },

                  { name: active ? 'Inactivate' : 'Activate', onClick: onActive }
                ] : []),

                { name: 'Remove', onClick: onRemove, props: { color: 'error' } }
              ]}
            />
          ) : undefined
        },
        {
          value: (
            <IconMaterialCircleW100FilledRounded
              color={status === 'invited' ? 'info' : active ? 'success' : 'error'}

              size='small'
            />
          )
        }
      ]}

      {...other}
    />
  )
});

export default Element; 