import { Line, RoundProgress } from '@amaui/ui-react';

const Element = () => {

  return (
    <Line
      justify='center'

      align='center'

      flex

      fullWidth
    >
      <RoundProgress
        color='secondary'

        size='large'
      />
    </Line>
  );
};

export default Element; 
