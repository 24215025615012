import React from 'react';

import { classNames } from '@amaui/style-react';
import { IWebsite } from '@amaui/api-utils';

import { FormPage, Items, NoResults, Page } from 'ui';
import { IPage } from 'ui/layout/Page';
import { WebsiteService } from 'services';
import Item from './Item';

export interface IPages extends IPage {
  website: IWebsite;
}

const Element: React.FC<IPages> = React.forwardRef((props, ref: any) => {
  const {
    website,

    className,

    ...other
  } = props;

  const search: any = [
    {
      name: 'Name',
      type: 'text',
      property: 'name'
    },
    {
      name: 'Added at',
      type: 'date',
      property: 'added_at'
    }
  ];

  return (
    <Page
      ref={ref}

      name='Pages'

      singular='page'

      plural='pages'

      service={WebsiteService}

      queryObjectsName='queryWebsitePages'

      parent={website}

      search={search}

      FormAdd={FormPage}

      FormUpdate={FormPage}

      addProps={{
        fullScreen: true
      }}

      updateProps={{
        fullScreen: true
      }}

      secondary

      className={classNames([
        'amaui-Website-Pages',

        className
      ])}

      style={{
        padding: 0
      }}

      {...other}
    >
      {({ response }) => {
        if (!response?.length) return <NoResults />;

        return (
          <Items
            service={WebsiteService}

            queryObjectsName='queryWebsitePages'
          >
            {response?.map((item: any, index: number) => (
              <Item
                key={index}

                object={item}

                website={website}
              />
            ))}
          </Items>
        );
      }}
    </Page>
  );
});

export default Element;
