import React from 'react';
import { useNavigate } from 'react-router-dom';

import { capitalize, debounce } from '@amaui/utils';
import { style } from '@amaui/style-react';
import { Avatar, Badge, IconButton, Line, List, ListItem, Menu, Tooltip, Type, useSnackbars, useSubscription } from '@amaui/ui-react';
import { INotification } from '@amaui/api-utils';

import IconMaterialNotificationsRounded from '@amaui/icons-material-rounded-react/IconMaterialNotificationsW100';

import { ReactComponent as LogoAmaui } from 'assets/svg/logo.svg';
import { ReactComponent as LogoTask } from 'assets/svg/logos/logo-task.svg';
import { ReactComponent as LogoNote } from 'assets/svg/logos/logo-note.svg';

import { AuthService, NotificationService } from 'services';
import { LOAD_MORE_LIMIT, NOTIFICATION_VERSIONS, getDate, getErrorMessage } from 'utils';
import { IQuerySubscription, ISignedIn } from 'types';

const useStyle = style(theme => ({
  root: {

  },

  menu: {
    padding: '0 0 12px',
    overflow: 'hidden',

    '&.amaui-Line-root': {
      width: '100vw',
      maxWidth: 340,
      borderRadius: theme.methods.shape.radius.value(1),
      boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.04)',
      background: theme.palette.light ? theme.palette.color.neutral[100] : theme.palette.color.neutral[5]
    },

    '& .amaui-ListItem-wrapper': {
      background: 'none'
    }
  },

  header: {
    padding: '24px 20px 12px',
    cursor: 'default',
    userSelect: 'none'
  },

  main: {
    maxHeight: 340,
    overflow: 'hidden auto'
  },

  unread: {
    position: 'absolute',
    top: '8px',
    right: '12px',
    width: 6,
    height: 6,
    background: theme.methods.palette.color.value('secondary', 40),
    borderRadius: '50%'
  },

  text: {
    '&.amaui-Type-root': {
      whiteSpace: 'initial',
      textWrap: 'wrap',
      wordBreak: 'break-word'
    }
  },

  textEmpty: {
    '&.amaui-Type-root': {
      padding: '16px 0px 16px',
      cursor: 'default',
      userSelect: 'none'
    }
  },

  meta: {
    marginTop: 8
  },

  metaLogo: {
    height: 16,
    width: 'auto'
  },

  divider: {
    '&.amaui-Divider-root': {
      opacity: theme.palette.light ? 0.07 : undefined
    }
  }
}), { name: 'amaui-app-Notifications' });

const Notifications = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();
  const navigate = useNavigate();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const queryObjects = useSubscription<IQuerySubscription>(NotificationService.queryObjects);

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState<any>(false);

  const refs = {
    items: React.useRef<HTMLDivElement>(),
    queryObjects: React.useRef(queryObjects),
    loading: React.useRef<any>(),
    timeout: React.useRef<any>()
  };

  refs.queryObjects.current = queryObjects;

  refs.loading.current = loading;

  const notificationsUnread = queryObjects.response?.filter((item: any) => !item.read).length || 0;

  const init = React.useCallback(async () => {
    const result = await NotificationService.refetch();

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
  }, []);

  const onRead = React.useCallback(async () => {
    const result = await NotificationService.read();

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }

    // Todo
    // optimize with a better way
    // to update unread value
    await AuthService.me();

    NotificationService.refetch();
  }, []);

  React.useEffect(() => {
    // init
    init();
  }, [signedIn]);

  const onLoadMore = React.useCallback(async () => {
    setLoading('query');

    const result = await NotificationService.refetch({
      query: {
        ...NotificationService.queryObjects.value!.previousQuery,

        next: refs.queryObjects.current?.pagination?.next,
        previous: undefined,
        skip: undefined,
        total: undefined,

        loadMore: true
      }
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }

    setLoading(false);
  }, []);

  const loadMore = React.useCallback(debounce(() => {
    const items = refs.items.current as HTMLElement;

    const limit = items.scrollHeight - (items.scrollTop + items.clientHeight);

    if (limit <= LOAD_MORE_LIMIT) {
      if (!refs.queryObjects.current?.pagination?.hasNext) return;

      // If not in loading process at the moment
      if (!refs.loading.current) {
        clearTimeout(refs.timeout.current);

        refs.timeout.current = setTimeout(onLoadMore, 140);
      }
    }
  }, 14), []);

  const onScroll = React.useCallback(loadMore, []);

  React.useEffect(() => {
    if (refs.items.current) (refs.items.current as HTMLElement).addEventListener('scroll', onScroll);

    return () => {
      if (refs.items.current) (refs.items.current as HTMLElement).removeEventListener('scroll', onScroll);
    };
  }, [open, refs.items.current]);

  const onOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = React.useCallback(() => {
    setOpen(false);

    if (!!notificationsUnread) onRead();
  }, [notificationsUnread, onRead]);

  const notifications = queryObjects.response;

  const getNotificationText = React.useCallback((item: INotification, title = true) => {
    const from = item.from?.users?.[0];

    const user = from?.name || 'User';

    if (item.version === NOTIFICATION_VERSIONS.taskAssign) {
      return title ? `${user} assignment` : `${user} assigned you to a task`;
    }

    if (item.version === NOTIFICATION_VERSIONS.noteMention) {
      return title ? `${user} mention` : `${user} mentioned you in a note`;
    }

    return '';
  }, []);

  const onClickNotification = React.useCallback((item: INotification) => {
    if (item.version === NOTIFICATION_VERSIONS.noteMention) navigate(`/notes/mentions`);

    if (item.version === NOTIFICATION_VERSIONS.taskAssign) navigate(`/tasks/assigned-to-me`);

    onClose();
  }, [onClose]);

  const getNotification = React.useCallback((item: INotification, index: number) => {
    const title = getNotificationText(item);

    if (!title) return null;

    const app = item.app || 'amaui';

    let logo: any;

    const logoProps: any = {
      className: classes.metaLogo
    };

    if (app === 'note') logo = <LogoNote {...logoProps} />;
    else if (app === 'task') logo = <LogoTask {...logoProps} />;
    else logo = <LogoAmaui {...logoProps} />;

    return (
      <ListItem
        key={index}

        start={(
          <Avatar
            color='primary'

            size='small'
          >
            {item.from?.users?.[0]?.name?.slice(0, 1)}
          </Avatar>
        )}

        primary={(
          <Type
            version='b2'

            weight={400}
          >
            {title}
          </Type>
        )}

        secondary={(
          <Type
            version='b2'
          >
            {getNotificationText(item, false)}
          </Type>
        )}

        tertiary={(
          <Line
            direction='row'

            align='center'

            justify='space-between'

            fullWidth

            className={classes.meta}
          >
            <Line
              gap={0.75}

              direction='row'

              align='center'
            >
              {logo}

              <Type
                version='b3'

                align='left'

                weight={100}
              >
                {['amaui'].includes(app) ? app : capitalize(app)}
              </Type>
            </Line>

            <Type
              version='b3'

              align='right'

              weight={100}
            >
              {getDate(item.added_at!, 'entire')}
            </Type>
          </Line>
        )}

        end={!item.read && (
          <Line
            className={classes.unread}
          />
        )}

        startAlign='start'

        endAlign='start'

        onClick={() => onClickNotification(item)}

        size='small'

        className={classes.notification}

        noBackground

        button
      />
    );
  }, [onClickNotification]);

  const menu = (
    <Line
      gap={0}

      className={classes.menu}

      fullWidth
    >
      <Line
        className={classes.header}

        fullWidth
      >
        <Type
          version='t2'
        >
          Notifications
        </Type>
      </Line>

      <Line
        ref={refs.items}

        gap={0}

        align='center'

        fullWidth

        className={classes.main}
      >
        {queryObjects.loaded && !notifications?.length && (
          <Line
            gap={1}

            direction='column'

            align='center'

            justify='center'

            fullWidth

            style={{
              paddingBlock: 54
            }}
          >
            <img
              alt=''

              src='/assets/images/not-found.png'

              style={{
                width: '100%',
                maxWidth: 240
              }}
            />

            <Type
              version='b1'

              align='center'
            >
              No notifications yet
            </Type>
          </Line>
        )}

        <List
          paddingHorizontal='none'

          paddingVertical='none'

          noBackground
        >
          {notifications?.map((item: any, index: number) => getNotification(item, index))}
        </List>
      </Line>
    </Line>
  );

  return (
    <Menu
      open={open}

      onOpen={onOpen}

      onClose={onClose}

      name={menu}

      position='bottom'

      alignment='center'

      AppendProps={{
        offset: [0, 8],
        switch: false
      }}

      {...other}
    >
      <span>
        <Tooltip
          name='Notifications'
        >
          <IconButton
            selected={open}
          >
            <Badge
              color='error'

              max={14}

              value={notificationsUnread >= 1 ? notificationsUnread : undefined}
            >
              <IconMaterialNotificationsRounded />
            </Badge>
          </IconButton>
        </Tooltip>
      </span>
    </Menu>
  );
});

export default Notifications;
