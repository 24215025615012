import React from 'react';

import { download, getCountry, is, stringToColor } from '@amaui/utils';
import { IconButton, Line, PieChart, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Type, useSnackbars, useSubscription } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { AmauiDate, format } from '@amaui/date';
import { Analytic, IWebsite } from '@amaui/api-utils';

import IconMaterialIosShareRounded from '@amaui/icons-material-rounded-react/IconMaterialIosShareW100';
import IconMaterialRefreshRounded from '@amaui/icons-material-rounded-react/IconMaterialRefreshW100';

import { AnalyticValue, Analytics, Input, Select } from 'ui';
import { AnalyticService } from 'services';
import { formats, getErrorMessage } from 'utils';
import { IQuerySubscription } from 'types';

const useStyle = style(theme => ({
  root: {

  },

  surface: {
    '&.amaui-Surface-root': {
      background: theme.palette.color.primary[theme.palette.light ? 99 : 5]
    }
  },

  chart: {
    width: '100%'
  },

  ...theme.classes(theme)
}), { name: 'amaui-app-Website-Analytics' });

export interface IWebsiteAnalytics {
  object: IWebsite;
}

const Element: React.FC<IWebsiteAnalytics> = React.forwardRef((props, ref: any) => {
  const {
    object
  } = props;

  const { classes } = useStyle();

  const snackbars = useSnackbars();
  const queryWebsiteAnalytics = useSubscription<IQuerySubscription>(AnalyticService.queryWebsiteAnalytics);

  const [loading, setLoading] = React.useState<any>(false);
  const [analyticsPer, setAnalyticsPer] = React.useState<any>('');

  const refs = {
    queryWebsiteAnalytics: React.useRef(queryWebsiteAnalytics)
  };

  refs.queryWebsiteAnalytics.current = queryWebsiteAnalytics;

  const dates = React.useMemo(() => {
    const today = AmauiDate.utc;

    return [
      { name: 'Total', value: '', unit: 'total' },
      { name: 'Hour', value: format(today, 'YYYY-MM-DD HH'), unit: 'hour' },
      { name: 'Today', value: format(today, 'YYYY-MM-DD'), unit: 'day' },
      { name: 'Month', value: format(today, 'YYYY-MM'), unit: 'month' },
      { name: 'Year', value: format(today, 'YYYY'), unit: 'year' }
    ];
  }, []);

  const init = React.useCallback(async () => {
    if (object) {
      const result = await AnalyticService.queryWebsiteAnalytics.value!.query({
        query: {
          query: {
            user: null,
            version: [`websites-${object?.id}-visit`, `websites-${object?.id}-visit-location`],
            date: dates.map((item: any) => item.value)
          }
        }
      });

      if (result.status >= 400) {
        snackbars.add({
          color: 'error',
          primary: getErrorMessage(result)
        });
      }
    }
  }, [object, dates]);

  React.useEffect(() => {
    // init
    init();
  }, []);

  const onRefresh = React.useCallback(async () => {
    setLoading('refresh');

    await AnalyticService.queryWebsiteAnalytics.value!.refetch();

    setLoading(false);
  }, []);

  const onExport = React.useCallback(async () => {
    const value = queryWebsiteAnalytics?.response;

    let values = is('array', value) ? value : [value];

    const properties = ['value', 'date', 'version', 'added_at', 'updated_at'];

    values = values.map((item: any) => {
      const object: any = {};

      properties.forEach((property: any) => {
        if (item[property]) object[property] = item[property];
      });

      return object;
    });

    const valueExport = {
      version: 'export',
      objects: 'website-analytics',
      value: values
    };

    download(`Form responses ${format(AmauiDate.amauiDate, formats.entire)}`, JSON.stringify(valueExport, null, 2), 'application/json');
  }, []);

  const onChangeAnalyticsPer = React.useCallback((value: any) => {
    setAnalyticsPer(value);
  }, []);

  const getAnalytic = (version: string, date: any, unit?: string): any => {
    return (queryWebsiteAnalytics?.response || []).find((item: Analytic) => item.version === version && (date !== undefined ? date === item.date : item.unit === unit));
  };

  const getPieChart = () => {
    const analytic = getAnalytic(`websites-${object?.id}-visit-location`, analyticsPer);

    if (analytic) {
      const countries = Object.keys(analytic.value);

      return (
        <PieChart
          title='Clicks per country'

          subtitle='In %'

          values={countries.map((item: any) => ({
            color: stringToColor(getCountry(item)?.name) as any,
            name: getCountry(item)?.name,
            values: [analytic.value[item]]
          }))}

          className={classNames([
            classes.chart,
            classes.pieChart,
            countries.length === 1 && classes.pieChart100
          ])}
        />
      );
    }

    return null;
  };

  const getCountryTable = () => {
    const analytic = getAnalytic(`websites-${object?.id}-visit-location`, analyticsPer);

    if (analytic) {
      const countries = Object.keys(analytic.value);

      return (
        <Table>
          <TableHead>
            <TableRow>
              {['Name', 'Visits'].map((item: string, index: number) => (
                <TableCell
                  key={index}
                >
                  <Type
                    version='b2'
                  >
                    {item}
                  </Type>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {countries.map((item: any, index: number) => (
              <TableRow
                key={index}
              >
                <TableCell
                  key={index}
                >
                  <Type
                    version='b2'
                  >
                    {getCountry(item)?.name}
                  </Type>
                </TableCell>

                <TableCell
                  key={index}
                >
                  <Type
                    version='b2'
                  >
                    {analytic.value[item]}
                  </Type>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    }

    return null;
  };

  const analyticSelected = getAnalytic(`websites-${object?.id}-visit-location`, analyticsPer);

  const iconProps: any = {
    size: 'large'
  };

  return (
    <Line
      ref={ref}

      fullWidth
    >
      <Input
        gap={1}

        name='Clicks'

        fullWidth

        end={(
          <Line
            gap={1}

            direction='row'

            align='center'

            justify='flex-end'

            fullWidth
          >
            <Tooltip
              name='Export'
            >
              <IconButton
                onClick={onExport}

                disabled={loading}
              >
                <IconMaterialIosShareRounded {...iconProps} />
              </IconButton>
            </Tooltip>

            <Tooltip
              name='Refresh'
            >
              <IconButton
                onClick={onRefresh}

                disabled={loading}
              >
                <IconMaterialRefreshRounded {...iconProps} />
              </IconButton>
            </Tooltip>
          </Line>
        )}
      >
        <Analytics
          align='flex-end'
        >
          {dates.map((item, index: number) => (
            <AnalyticValue
              key={index}

              name={item.name.trim()}

              value={getAnalytic(`websites-${object?.id}-visit`, undefined, item.unit)?.value || 0}
            />
          ))}
        </Analytics>
      </Input>

      <Input
        gap={1}

        name='Analytics'

        fullWidth

        style={{
          overflowX: 'hidden'
        }}
      >
        <Line
          align='flex-end'

          fullWidth
        >
          <Select
            valueDefault=''

            value={analyticsPer}

            onChange={onChangeAnalyticsPer}

            options={dates}
          />
        </Line>

        <Analytics
          gap={2}

          align='center'

          direction='column'

          style={{
            padding: '16px 8px 8px'
          }}
        >
          {!analyticSelected && (
            <Line
              align='center'

              justify='center'

              style={{
                height: 140
              }}
            >
              <Type
                version='b1'

                align='center'
              >
                No visits recorded at the moment
              </Type>
            </Line>
          )}

          {getPieChart()}

          {getCountryTable()}
        </Analytics>
      </Input>
    </Line>
  );
});

export default Element;
