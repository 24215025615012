import AmauiSub from '@amaui/subscription';
import AmauiRequest from '@amaui/request';

import { IAdd } from 'ui/layout/Add';
import { AuthService } from 'services';

class AppService {
  public pages = {
    add: new AmauiSub<IAdd>(),
    addSecondary: new AmauiSub<IAdd>(),
    addTertiary: new AmauiSub<IAdd>()
  };

  public init() {
    AmauiRequest.interceptors.response.error.subscribe(async (value: any) => {
      // Authentication error
      if (value.status === 401) await AuthService.signOut(false);
    });
  }

  public constructor() {
    this.init();
  }

}

export default new AppService();
