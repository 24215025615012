import { Media } from '@amaui/api-utils';
import AmauiSubscription from '@amaui/subscription';

import { BaseService } from './base';
import { IQuerySubscription, ISelectedSubscription } from 'types';

class MediaService extends BaseService<Media> {
  public queryObjectsModal = new AmauiSubscription<IQuerySubscription>();
  public selectObjectModal = new AmauiSubscription<ISelectedSubscription>();

  public constructor() {
    super('medias');

    this.queryObjectsInit(this.queryObjectsModal);

    this.selectedObjectsInit(this.selectObjectModal);
  }

}

export default new MediaService();
