import React from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { getRedirectTo } from '@amaui/utils';
import { NotFound, useSubscription } from '@amaui/ui-react';

import { getRootPage, lazy, wrapper } from 'utils';
import { AmauiService, AuthService } from 'services';
import { ISignedIn } from 'types';

import RootAuth from './pages/auth/Root';
import SignIn from './pages/auth/SignIn';
import SignUp from './pages/auth/SignUp';
import SignUpConfirm from './pages/auth/SignUpConfirm';
import ForgotPassword from './pages/auth/ForgotPassword';

import Main from './pages/main/Main';
import Library from './pages/main/Library/Library';
import LibraryAdd from './pages/main/Library/Add';

import SettingsInfo from './pages/main/UserSettings/Info';
import SettingsSecurity from './pages/main/UserSettings/Security';
import SettingsNotifications from './pages/main/UserSettings/Notifications';

import OrganizationSettingsInfo from './pages/main/OrganizationSettings/Info';
import OrganizationSettingsUsers from './pages/main/OrganizationSettings/Users/Users/Users';
import OrganizationSettingsUserGroups from './pages/main/OrganizationSettings/Users/UserGroups/UserGroups';
import OrganizationSettingsIntegrations from './pages/main/OrganizationSettings/Integrations';

import OrganizationSettingsSubscriptionsAmaui from './pages/main/OrganizationSettings/Subscription/Subscription';

const RootMain = lazy(React.lazy(() => import('./pages/main/Root')));
const Tasks = lazy(React.lazy(() => import('./pages/main/Tasks/Tasks')));
const TasksCalendar = lazy(React.lazy(() => import('./pages/main/Tasks/TasksCalendar')));
const TasksLists = lazy(React.lazy(() => import('./pages/main/Tasks/TasksLists')));
const Notes = lazy(React.lazy(() => import('./pages/main/Notes/Notes')));
const NotesMentions = lazy(React.lazy(() => import('./pages/main/Notes/NotesMentions')));
const UrlShorteners = lazy(React.lazy(() => import('./pages/main/URLShorteners/URLShorteners')));
const Chats = lazy(React.lazy(() => import('./pages/main/Chats/Chats')));
const Contacts = lazy(React.lazy(() => import('./pages/main/Contacts/Contacts')));
const Websites = lazy(React.lazy(() => import('./pages/main/Websites/Websites')));

// medias
const MediasRoot = lazy(React.lazy(() => import('./pages/main/Library')));

// settings
const SettingsRoot = lazy(React.lazy(() => import('./pages/main/UserSettings')));

// organization settings
const OrganizationSettingsRoot = lazy(React.lazy(() => import('./pages/main/OrganizationSettings/Root')));

const Routing = React.forwardRef((props: any, ref: any) => {
  const {
    ...other
  } = props;

  const navigate = useNavigate();

  const signInMethod = (value: any, setResponse: any) => {
    // Sign out
    if (refs.signedIn.current && value === null) {
      // redirect to
      const redirect_to = getRedirectTo();

      let to = `/sign-in`;

      if (AuthService.redirect) to += `?redirect_to=${redirect_to}`;

      navigate(to);
    }

    setResponse([value]);
  };

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn, signInMethod);
  const initial = useSubscription(AmauiService.initial);

  const refs = {
    signedIn: React.useRef(signedIn)
  };

  refs.signedIn.current = signedIn;

  // Only show routes
  // when we have all the info
  // about the auth, user
  if (!initial) return null;

  const is = signedIn?.user?.is;

  return (
    <Routes
      ref={ref}

      {...other}
    >
      {AuthService.isAuthenticated && (
        <Route
          element={wrapper(RootMain)}
        >
          <Route
            index

            element={wrapper(Main)}
          />

          {is?.user && (
            <Route
              element={wrapper(MediasRoot)}
            >
              <Route
                path='/library'

                element={wrapper(Library)}
              />

              <Route
                path='/library/upload'

                element={wrapper(LibraryAdd)}
              />
            </Route>
          )}

          {/* Tasks */}
          {is?.user && <>
            <Route
              path='/tasks'

              element={wrapper(Tasks)}
            />

            <Route
              path='/tasks/assigned-to-me'

              element={wrapper(Tasks)}
            />

            <Route
              path='/tasks/assigned-to-me/:id'

              element={wrapper(Tasks)}
            />

            <Route
              path='/tasks/all'

              element={wrapper(Tasks)}
            />

            <Route
              path='/tasks/all/add'

              element={wrapper(Tasks)}
            />

            <Route
              path='/tasks/all/:id'

              element={wrapper(Tasks)}
            />

            <Route
              path='/tasks/calendar'

              element={wrapper(TasksCalendar)}
            />

            <Route
              path='/tasks/calendar/:id'

              element={wrapper(TasksCalendar)}
            />

            <Route
              path='/tasks/lists'

              element={wrapper(TasksLists)}
            />

            <Route
              path='/tasks/lists/:id'

              element={wrapper(TasksLists)}
            />

            <Route
              path='/tasks/tomorrow'

              element={wrapper(Tasks)}
            />

            <Route
              path='/tasks/tomorrow/add'

              element={wrapper(Tasks)}
            />

            <Route
              path='/tasks/tomorrow/:id'

              element={wrapper(Tasks)}
            />

            <Route
              path='/tasks/week'

              element={wrapper(Tasks)}
            />

            <Route
              path='/tasks/week/add'

              element={wrapper(Tasks)}
            />

            <Route
              path='/tasks/week/:id'

              element={wrapper(Tasks)}
            />

            <Route
              path='/tasks/add'

              element={wrapper(Tasks)}
            />

            <Route
              path='/tasks/:id'

              element={wrapper(Tasks)}
            />
          </>}

          {/* Notes */}
          {is?.user && <>
            <Route
              path='/notes'

              element={wrapper(Notes)}
            />

            <Route
              path='/notes/mentions'

              element={wrapper(NotesMentions)}
            />

            <Route
              path='/notes/mentions/:id'

              element={wrapper(NotesMentions)}
            />

            <Route
              path='/notes/add'

              element={wrapper(Notes)}
            />

            <Route
              path='/notes/:id'

              element={wrapper(Notes)}
            />
          </>}

          {/* UrlShorteners */}
          {is?.user && <>
            <Route
              path='/url-shorteners'

              element={wrapper(UrlShorteners)}
            />

            <Route
              path='/url-shorteners/add'

              element={wrapper(UrlShorteners)}
            />

            <Route
              path='/url-shorteners/:id'

              element={wrapper(UrlShorteners)}
            />
          </>}

          {/* Chats */}
          {is?.user && <>
            <Route
              path='/chats'

              element={wrapper(Chats)}
            />

            <Route
              path='/chats/new'

              element={wrapper(Chats)}
            />

            <Route
              path='/chats/:id'

              element={wrapper(Chats)}
            />
          </>}

          {/* Contacts */}
          {is?.user && <>
            <Route
              path='/contacts'

              element={wrapper(Contacts)}
            />

            <Route
              path='/contacts/add'

              element={wrapper(Contacts)}
            />

            <Route
              path='/contacts/:id'

              element={wrapper(Contacts)}
            />
          </>}

          {/* Websites */}
          {is?.user && <>
            <Route
              path='/websites'

              element={wrapper(Websites)}
            />

            <Route
              path='/websites/add'

              element={wrapper(Websites)}
            />

            <Route
              path='/websites/:id'

              element={wrapper(Websites)}
            />
          </>}

          {/* Settings */}
          <Route
            element={wrapper(SettingsRoot)}
          >
            <Route
              path='/settings'

              element={wrapper(SettingsInfo)}
            />

            <Route
              path='/settings/security'

              element={wrapper(SettingsSecurity)}
            />

            <Route
              path='/settings/notifications'

              element={wrapper(SettingsNotifications)}
            />
          </Route>

          {is?.admin && (
            <Route
              element={wrapper(OrganizationSettingsRoot)}
            >
              <Route
                path='/organization/settings/info'

                element={wrapper(OrganizationSettingsInfo)}
              />

              <Route
                path='/organization/settings/employees'

                element={wrapper(OrganizationSettingsUsers)}
              />

              <Route
                path='/organization/settings/employees/add'

                element={wrapper(OrganizationSettingsUsers)}
              />

              <Route
                path='/organization/settings/employees/:id'

                element={wrapper(OrganizationSettingsUsers)}
              />

              <Route
                path='/organization/settings/employee-groups'

                element={wrapper(OrganizationSettingsUserGroups)}
              />

              <Route
                path='/organization/settings/employee-groups/add'

                element={wrapper(OrganizationSettingsUserGroups)}
              />

              <Route
                path='/organization/settings/employee-groups/:id'

                element={wrapper(OrganizationSettingsUserGroups)}
              />

              <Route
                path='/organization/settings/subscription'

                element={wrapper(OrganizationSettingsSubscriptionsAmaui)}
              />

              <Route
                path='/organization/settings/integrations'

                element={wrapper(OrganizationSettingsIntegrations)}
              />
            </Route>
          )}

          <Route
            path='*'

            element={(
              <NotFound
                to={getRootPage(signedIn)}

                ButtonProps={{
                  name: 'Back to home'
                }}
              />
            )}
          />
        </Route>
      )}

      {!AuthService.isAuthenticated && (
        <Route
          element={wrapper(RootAuth)}
        >
          <Route
            path='/sign-in'

            element={wrapper(SignIn)}
          />

          <Route
            path='/sign-up'

            element={wrapper(SignUp)}
          />

          <Route
            path='/sign-up/confirm'

            element={wrapper(SignUpConfirm)}
          />

          <Route
            path='/forgot-password'

            element={wrapper(ForgotPassword)}
          />

          <Route
            path='*'

            element={<Navigate to='/sign-in' />}
          />
        </Route>
      )}
    </Routes>
  );
});

export default Routing;
