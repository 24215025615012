import React from 'react';

import { is } from '@amaui/utils';
import { Line, Modal, ModalFooter, ModalMain, Slide, Tab, Tabs, Type, useSubscription } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';

import IconMaterialFileUploadW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialFileUploadW100';
import IconMaterialImagesmodeW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialImagesmodeW100';

import { Button } from 'ui';
import { MediaService } from 'services';
import { ISelectedSubscription } from 'types';
import MediaContext from './Context';
import Library from 'pages/main/Library/Library';
import LibraryAdd from 'pages/main/Library/Add';

const useStyle = style(theme => ({
  root: {
    '& .amaui-Modal-surface': {
      height: 'calc(100vh - 114px)',
      padding: 0,
      minWidth: 'clamp(0px, 1100px, calc(100vw - 48px))',
      background: theme.palette.light ? undefined : `${theme.palette.color.primary[10]} !important`
    },

    '& .amaui-ModalMain-root': {
      minHeight: 'clamp(0px, 370px, 53vh)',
      overflow: 'hidden auto'
    },

    '& .amaui-ModalFooter-root': {
      padding: 20
    }
  },

  header: {
    padding: '20px 20px 0'
  },

  tabs: {
    '& .amaui-Tabs-root': {
      width: 'auto'
    },

    '& .amaui-Tabs-line': {
      height: 2
    }
  },

  tab: {
    '&.amaui-Tab-root': {
      padding: '8px 20px'
    }
  }
}), { name: 'amaui-app-Library-Modal' });

export interface ILibraryOptions {
  search?: any;

  multiple?: boolean;

  onConfirm?: (selected: any[]) => any;

  selected?: any[];
}

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    search,

    children,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const selectedObjects = useSubscription<ISelectedSubscription>(MediaService.selectObjectModal);

  const [openModal, setOpenModal] = React.useState(false);
  const [options, setOptions] = React.useState<ILibraryOptions>({});
  const [tab, setTab] = React.useState('Library');

  const refs = {
    selectedObjects: React.useRef(selectedObjects)
  };

  refs.selectedObjects.current = selectedObjects;

  const update = React.useCallback(async (options?: ILibraryOptions) => {
    const {
      selected: selectedOption,

      ...otherOptions
    } = options || {};

    setOptions({
      multiple: false,

      ...otherOptions
    });

    if (options?.selected) {
      MediaService.selectObjectModal.value?.selectMany(options.selected);
    }
  }, []);

  const open = React.useCallback(async (options?: ILibraryOptions) => {
    await update(options);

    setOpenModal(true);
  }, [update]);

  const close = React.useCallback(() => {
    setOpenModal(false);
  }, []);

  const cleanUp = React.useCallback(() => {
    setTab('Library');

    setTimeout(() => {
      MediaService.selectObjectModal.value!.reset();
    }, 400);
  }, []);

  const onClose = React.useCallback(() => {
    setOpenModal(false);

    cleanUp();
  }, []);

  const onConfirm = React.useCallback(() => {
    if (is('function', options.onConfirm)) options.onConfirm!(refs.selectedObjects.current.objects || []);

    onClose();
  }, [options, onClose]);

  const onChangeTabs = React.useCallback((valueNew: any) => {
    setTab(valueNew);
  }, []);

  const onAdd = React.useCallback(() => {
    setTab('Upload');
  }, []);

  const onLibraryAdd = React.useCallback(() => {
    setTab('Library');
  }, []);

  const value = React.useMemo(() => {
    return {
      open,
      update,
      close
    };
  }, []);

  const tabs = React.useMemo(() => {
    return [
      { name: 'Library', Icon: IconMaterialImagesmodeW100Rounded },
      { name: 'Upload', Icon: IconMaterialFileUploadW100Rounded }
    ];
  }, []);

  const iconProps = {
    size: 'large'
  };

  return (
    <MediaContext.Provider
      value={value}
    >
      {children}

      <Modal
        tonal

        color='default'

        minWidth='xl'

        freezeScroll

        open={openModal}

        onClose={onClose}

        TransitionComponent={Slide}

        className={classNames([
          className,
          classes.root
        ])}

        {...other}

        SurfaceProps={{
          color: 'primary',
          tonal: true
        }}
      >
        <Line
          direction='row'

          justify='space-between'

          align='center'

          className={classes.header}
        >
          <Tabs
            size='large'

            justify='center'

            noDivider

            value={tab}

            onChange={onChangeTabs}

            className={classes.tabs}
          >
            {tabs?.map((item: any) => (
              <Tab
                key={item.to}

                value={item.value || item.name || item}

                icon={item.Icon ? <item.Icon {...iconProps} /> : null}

                className={classes.tab}
              >
                <Type
                  version='t2'
                >
                  {item.name}
                </Type>
              </Tab>
            ))}
          </Tabs>

          {/* <Tooltip
            name='Close'
          >
            <IconButton
              color='inherit'

              onClick={onClose}
            >
              <IconMaterialKeyboardArrowDownW100Rounded
                {...iconProps}
              />
            </IconButton>
          </Tooltip> */}
        </Line>

        <ModalMain
          align='unset'

          justify='unset'
        >
          {tab === 'Library' && (
            <Library
              queryDefault={{
                ...options?.search
              }}

              queryObjectsName='queryObjectsModal'

              selectObjectsName='selectObjectModal'

              select

              onAdd={onAdd}

              multiple={options?.multiple}

              open={false}

              searches={{
                name: true,
                type: !options?.search?.mime?.length,
                date: true
              }}

              style={{
                ...(tab !== 'Library' && { display: 'none ' })
              }}

              modal
            />
          )}

          {tab === 'Upload' && (
            <LibraryAdd
              onAdd={onLibraryAdd}
            />
          )}
        </ModalMain>

        <ModalFooter>
          <Button
            version='text'

            color='inherit'

            onClick={onClose}
          >
            Close
          </Button>

          <Button
            onClick={onConfirm}
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </MediaContext.Provider>
  );
});

export default Element;
