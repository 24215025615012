import React from 'react';

import { CalendarViews } from '@amaui/ui-react';
import { ICalendarViews } from '@amaui/ui-react/CalendarViews/CalendarViews';

import IconMaterialArrowForwardIosW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialArrowForwardIosW100';
import IconMaterialArrowBackIosNewW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialArrowBackIosNewW100';

const Element: React.FC<ICalendarViews> = props => {

  return (
    <CalendarViews
      IconPrevious={IconMaterialArrowBackIosNewW100Rounded}

      IconNext={IconMaterialArrowForwardIosW100Rounded}

      {...props}
    />
  );
};

export default Element;
