import React from 'react';
import { useStripe } from '@stripe/react-stripe-js';

import { capitalize, to, wait } from '@amaui/utils';
import { IconButton, Line, ListItem, Modal, Properties, Slide, Tab, Tabs, Tooltip, Type, useConfirm, useForm, useSnackbars } from '@amaui/ui-react';
import { classNames, style } from '@amaui/style-react';
import { IPlan, Plan } from '@amaui/api-utils';

import IconMaterialReceiptW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialReceiptW100';
import IconMaterialCreditCardW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialCreditCardW100';
import IconMaterialAccountBalanceWalletW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialAccountBalanceWalletW100';
import IconMaterialCloseRounded from '@amaui/icons-material-rounded-react/IconMaterialCloseW100';

import { ReactComponent as Logo } from 'assets/svg/logo.svg';
import { ReactComponent as LogoPersonalTrainer } from 'assets/svg/logos/logo-personal-trainer.svg';

import { Button, Divider, FormPaymentMethod, Input, Inputs, Items, Loading, NoResults, NumericTextField, Page, Paper, Select } from 'ui';
import { AuthService, OrganizationService } from 'services';
import { getErrorMessage } from 'utils';
import ItemInvoice from './ItemInvoice';
import ItemPaymentCard from './ItemPaymentCard';

const useStyle = style(theme => ({
  root: {

  },

  plans: {
    paddingInline: 4
  },

  plan: {
    width: '100vw',
    maxWidth: '100%',
    minWidth: 250,
    padding: 24,
    borderRadius: 8,
    background: theme.palette.background.default[theme.palette.light ? 'primary' : 'quaternary'],
    boxShadow: theme.shadows.values.default[1],
    cursor: 'pointer',
    transition: theme.methods.transitions.make('transform'),

    '&:active': {
      transform: 'scale(0.94)'
    }
  },

  planSelected: {
    outline: `3px solid ${theme.palette.color.primary.main}`
  },

  logo: {
    height: 40,
    width: 'auto'
  }
}), { name: 'amaui-app-Subscription' });

const Element = React.forwardRef(() => {
  const { classes } = useStyle();

  const snackbars = useSnackbars();
  const confirm = useConfirm();

  const [response, setResponse] = React.useState<any>({});
  const [openModal, setOpenModal] = React.useState(false);
  const [plans, setPlans] = React.useState<Plan[]>();
  const [upcomingInvoice, setUpcomingInvoice] = React.useState<any>();
  const [paymentMethod, setPaymentMethod] = React.useState<any>();
  const [loaded, setLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState<any>();
  const [tab, setTab] = React.useState('Manage');

  const {
    subscription,
    paymentMethods = [],
    plan,
    organizationPlan
  } = response;

  const cancel_at_period_end = organizationPlan?.plan?.cancel_at_period_end;

  // const isFree = plan?.name === 'Free';

  const stripe = useStripe();

  const form = useForm({
    values: {
      plan: {
        name: 'Plan'
      },

      media: {
        name: 'Media'
      },

      recurring: {
        name: 'Recurring',
        is: 'string',
        in: ['month', 'year'],
        value: 'month'
      }
    }
  });

  const refs = {
    initial: React.useRef<any>({}),
    form: React.useRef(form)
  };

  refs.form.current = form;

  const init = React.useCallback(async () => {
    setLoading('initial');

    const result = await OrganizationService.getSubscription();

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      const response = result.response.response;

      // plans 
      const plans_: IPlan[] = response.plans;

      plans_.sort((a, b) => +a.prices?.[0].unit_amount - +b.prices?.[0].unit_amount);

      setPlans(plans_ as any);

      // form
      const planPrice = response.subscription?.items?.data?.find((item: any) => {
        return item.metadata.lookup_key.includes('--plan--');
      });

      // payment method
      const paymentMethod_ = response.paymentMethods?.find((item: any) => item.default) || response.paymentMethods[0];

      if (paymentMethod_) setPaymentMethod(paymentMethod_);

      // plan
      refs.initial.current.plan = response.plan;

      refs.initial.current.recurring = planPrice?.price?.recurring?.interval;

      const items = response.subscription.items.data || [];

      const itemsSubscription: any = {};

      items.forEach((item: any) => {
        if (item.price.lookup_key.includes('media')) itemsSubscription.media = item;
      });

      form.onChange([
        ['plan', response.plan],

        // media 
        ['media', itemsSubscription.media?.quantity || 0]
      ]);

      setResponse(response);
    }

    setLoading(false);

    setLoaded(true);
  }, [form]);

  const reinit = React.useCallback(async () => {
    setLoading('reinit');

    const result = await OrganizationService.getSubscription();

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      const response = result.response.response;

      // payment method
      const paymentMethod_ = response.paymentMethods?.find((item: any) => item.default) || response.paymentMethods[0];

      if (paymentMethod_) setPaymentMethod(paymentMethod_);

      setResponse(response);
    }

    setLoading(false);

    setLoaded(true);
  }, [form]);

  React.useEffect(() => {
    // init
    init();
  }, []);

  const onUpdate = React.useCallback(async () => {
    setLoading('pay');

    const subscriptionResponse = await OrganizationService.updateSubscription({
      plan: {
        id: refs.form.current.values.plan.value?.id
      },

      resources: {
        media: refs.form.current.values.media.value || 0
      }
    });

    if (subscriptionResponse.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(subscriptionResponse)
      });
    }
    else {
      // Payment intent
      const subscriptionUpdateRequest = subscriptionResponse.response.response;

      const payment_intent = subscriptionUpdateRequest.latest_invoice.payment_intent;

      if (payment_intent && payment_intent.status !== 'succeeded') {
        const payment_method = paymentMethod;

        const result = await stripe!.confirmCardPayment(payment_intent?.client_secret, {
          payment_method: payment_method.id,
        });

        if (result.error) {
          snackbars.add({
            color: 'error',
            primary: result.error.message
          });
        }
        else {
          await wait(4400);

          // init 
          init();

          snackbars.add({
            primary: 'Subscription updated'
          });

          onCloseModal();
        }
      }
      else {
        await wait(4400);

        // init 
        await init();

        await AuthService.me();

        snackbars.add({
          primary: 'Subscription updated'
        });

        onCloseModal();
      }
    }

    setLoading(false);
  }, []);

  const onUpdateCancel = React.useCallback(async () => {
    if (!cancel_at_period_end) {
      const confirmed = await confirm.open({
        name: 'You are about to cancel your subscription'
      });

      if (!confirmed) return;
    }

    setLoading('update-cancel');

    const result = await OrganizationService.updateSubscription({
      plan: {
        id: refs.form.current.values.plan.value?.id
      },

      cancel_at_period_end: !cancel_at_period_end
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Subscription ${cancel_at_period_end ? 'resumed' : 'cancelled'}`
      });
    }

    setLoading(false);

    // refresh 
    init();
  }, [form, cancel_at_period_end]);

  const onChangeTabs = React.useCallback((valueNew: any) => {
    setTab(previous => {
      if (previous !== valueNew && valueNew === 'Subscription') reinit();

      return valueNew;
    });
  }, []);

  const paymentCards = React.useMemo(() => {
    return (
      <Page
        name='Payment cards'

        singular='payment card'

        plural='payment cards'

        service={OrganizationService}

        queryObjectsName='queryPaymentMethods'

        secondary

        FormAdd={FormPaymentMethod}

        stripe

        className={classNames([
          'amaui-PaymentCards'
        ])}
      >
        {({ response }) => {
          if (!response?.length) return <NoResults />;

          return (
            <Items
              service={OrganizationService}

              queryObjectsName='queryPaymentMethods'

              stripe
            >
              {response?.map((item: any, index: number) => (
                <ItemPaymentCard
                  key={index}

                  object={item}
                />
              ))}
            </Items>
          );
        }}
      </Page>
    );
  }, []);

  const invoices = React.useMemo(() => {
    return (
      <Page
        name='Invoices'

        singular='invoice'

        plural='invoices'

        service={OrganizationService}

        queryObjectsName='queryInvoices'

        secondary

        add={false}

        stripe

        className={classNames([
          'amaui-Invoices'
        ])}
      >
        {({ response }) => {
          if (!response?.length) return <NoResults />;

          return (
            <Items
              service={OrganizationService}

              queryObjectsName='queryInvoices'

              stripe
            >
              {response?.map((item: any, index: number) => (
                <ItemInvoice
                  key={index}

                  object={item}
                />
              ))}
            </Items>
          );
        }}
      </Page>
    );
  }, []);

  const onAddPaymentCard = React.useCallback(() => {
    setTab('Payment cards');

    onCloseModal();
  }, []);

  const onUpdatePreview = React.useCallback(async () => {
    setLoading('update');

    try {
      // Preview the invoice
      const invoiceResponse = await OrganizationService.updateSubscription({
        plan: {
          id: refs.form.current.values.plan.value?.id
        },

        resources: {
          media: refs.form.current.values.media.value || 0
        },

        upcoming_invoice: true
      });

      if (invoiceResponse.status >= 400) {
        snackbars.add({
          color: 'error',
          primary: getErrorMessage(invoiceResponse)
        });
      }
      else {
        setUpcomingInvoice(invoiceResponse.response.response);

        setOpenModal(true);
      }
    }
    catch (error) {
    }

    setLoading(false);
  }, []);

  const updated = React.useMemo(() => {
    if (!response?.subscription) return false;

    const items = response.subscription.items.data || [];

    const itemsSubscription: any = {};

    items.forEach((item: any) => {
      if (item.price.lookup_key.includes('media')) itemsSubscription.media = item;
    });

    const result = (
      response.plan.id === form.values.plan.value?.id &&
      (itemsSubscription.media?.quantity || 0) === (form.values.media.value || 0)
    );

    return !result;
  }, [form, response]);

  const onSelect = React.useCallback((item: any) => {
    refs.form.current.onChange('plan', item);
  }, []);

  const onCloseModal = React.useCallback(() => {
    setOpenModal(false);
  }, []);

  const planSelected = refs.form.current.value.plan;

  const priceMap = React.useMemo(() => {
    const result: any = {};

    if (!planSelected) return result;

    planSelected.prices.forEach((price: any) => {
      const [, v] = price.lookup_key.split('--');

      result[v] = price;
    });

    return result;
  }, [planSelected]);

  const listItemProps: any = {
    size: 'small',
    Component: 'div',
    noPadding: true,
    noBackground: true
  };

  const iconProps: any = {
    size: 'large'
  };

  const propertiesProps: any = {
    rowGap: 1.25,
    version: 'row',
    size: 'large'
  };

  const getPlan = React.useCallback((item: Plan) => {
    const name = item.name;
    const selected = refs.form.current.value.plan?.id === item.id;

    const pricePlan = item.prices.find(itemPrice => itemPrice.lookup_key.includes('--plan--'));

    const provided = item.provided.use;

    return (
      <Line
        key={item.id}

        gap={3}

        onClick={() => onSelect(item)}

        className={classNames([
          classes.plan,

          selected && classes.planSelected
        ])}
      >
        <Line
          gap={0.5}

          align='center'

          fullWidth
        >
          <Type
            version='h3'
          >
            {name}
          </Type>

          <Type
            version='b1'
          >
            {pricePlan?.unit_amount / 100} EUR/mo
          </Type>
        </Line>

        <Line
          gap={1}
        >
          <Line
            fullWidth
          >
            <Line
              gap={1}

              direction='row'

              align='center'

              justify='center'

              fullWidth
            >
              <LogoPersonalTrainer
                className={classes.logo}
              />
            </Line>

            <Properties
              values={[
                { name: 'Clients', value: provided?.personalTrainerCustomer?.total }
              ]}

              {...propertiesProps}
            />
          </Line>

          <Divider
            size='small'
          />

          <Line
            fullWidth
          >
            <Line
              gap={1}

              direction='row'

              align='center'

              justify='center'

              fullWidth
            >
              <Logo
                className={classes.logo}
              />

              <Type
                version='b2'
              >
                More features
              </Type>
            </Line>

            <Properties
              values={[
                { name: 'Websites', value: provided?.website?.total },
                { name: 'Media storage', value: to(provided?.media?.total, 'size-format') }
              ]}

              {...propertiesProps}
            />
          </Line>
        </Line>
      </Line>
    );
  }, []);

  const priceCurrent = React.useMemo(() => {
    if (!subscription) return 0;

    const value = subscription.items.data.reduce((result: number, item: any) => result += item.price.unit_amount * item.quantity, 0);

    return value / 100;
  }, [subscription]);

  const main = <>
    {!loaded && (
      <Loading />
    )}

    {loaded && <>
      <Paper
        width={1100}
      >
        <Inputs
          footer={(
            <Button
              onClick={onUpdatePreview}

              disabled={!updated || loading}
            >
              Preview updates
            </Button>
          )}

          center
        >
          <Line
            align='center'

            fullWidth
          >
            <Line
              gap={1}

              align='center'

              fullWidth
            >
              <Line
                gap={0}

                align='center'

                fullWidth
              >
                <Type
                  version='b1'

                  align='center'
                >
                  In use
                </Type>

                <Type
                  version='h2'

                  align='center'
                >
                  {plan?.name} subscription
                </Type>
              </Line>

              <Type
                version='b1'

                align='center'
              >
                {priceCurrent} EUR/mo
              </Type>
            </Line>
          </Line>

          <Line
            gap={1.5}

            direction='row'

            align='flex-start'

            fullWidth

            className={classNames([
              'amaui-overflow-x',

              classes.plans
            ])}
          >
            {plans?.map((item: any) => getPlan(item))}
          </Line>

          <Divider
            size='small'

            smaller
          />

          <Input
            name='Additional media storage'

            description={(
              <Type
                version='b2'
              >
                You can buy more storage on top of media storage you get with your plan. <br />

                Price in the selected plan is {priceMap['media']?.unit_amount_decimal / 100} EUR per 1GB / month.
              </Type>
            )}
          >
            <NumericTextField
              value={form.values.media.value || 0}

              onChange={(valueNew: string) => form.onChange('media', valueNew)}

              min={0}

              max={150}

              sufix='GBs'
            />
          </Input>
        </Inputs>

        <Divider />

        <Input
          name={`${cancel_at_period_end ? 'Resume' : 'Cancel'} your subscription`}

          description={cancel_at_period_end ? 'Resume to keep using all the features in the subscription.' : 'If you cancel, you can still use your subscription until it expires.'}

          footer={(
            <Button
              onClick={onUpdateCancel}

              version='outlined'

              color={cancel_at_period_end ? 'success' : 'error'}

              disabled={loading}
            >
              {cancel_at_period_end ? 'Resume subscription' : 'Cancel subscription'}
            </Button>
          )}
        />
      </Paper>

      <Modal
        open={openModal}

        minWidth='rg'

        onClose={onCloseModal}

        TransitionComponent={Slide}
      >
        <Line
          direction='row'

          justify='space-between'

          align='center'
        >
          <Type
            version='h3'

            align='center'
          >
            Update preview
          </Type>

          <Tooltip
            name='Close'
          >
            <IconButton
              color='inherit'

              onClick={onCloseModal}
            >
              <IconMaterialCloseRounded
                {...iconProps}
              />
            </IconButton>
          </Tooltip>
        </Line>

        <Line
          gap={1}

          align='unset'

          fullWidth
        >
          <Line
            gap={1}

            style={{
              marginTop: 12
            }}
          >
            {planSelected?.prices?.map((item: any, index: number) => {
              const [, v] = item.lookup_key.split('--');

              let name = v;
              let quantity = v;

              if (quantity === 'plan') quantity = 1;
              else quantity = form.values[v]?.value || 0;

              if (name === 'media') name = `Media storage in GB`;
              else if (name === 'plan') name = `${planSelected.name} plan`;

              const price = ((item.unit_amount || item.unit_amount_decimal) / 100) * quantity;

              if (['user', 'website'].includes(name)) return null;

              return (
                <ListItem
                  key={index}

                  primary={(
                    <Type
                      version='t3'
                    >
                      {capitalize(name)}{v === 'plan' ? '' : ` x ${form.values[v]?.value || 0}`}
                    </Type>
                  )}

                  end={(
                    <Type
                      version='b2'
                    >
                      {!Number.isInteger(price) ? Number(price).toFixed(2) : price} EUR/mo
                    </Type>
                  )}

                  {...listItemProps}
                />
              );
            })}
          </Line>

          <Divider
            size='small'
          />

          <Line
            gap={4}

            fullWidth
          >
            <Line
              gap={1.5}

              fullWidth
            >
              <ListItem
                primary={(
                  <Type
                    version='t3'
                  >
                    Total
                  </Type>
                )}

                end={(
                  <Type
                    version='b2'
                  >
                    {upcomingInvoice?.total / 100} {capitalize(upcomingInvoice?.currency)}
                  </Type>
                )}

                {...listItemProps}
              />

              {(upcomingInvoice?.total !== upcomingInvoice?.amount_due) && (
                <ListItem
                  primary={(
                    <Type
                      version='t3'
                    >
                      {upcomingInvoice?.total < 0 ? 'Future credits' : 'Starting balance'}
                    </Type>
                  )}

                  end={(
                    <Type
                      version='b2'
                    >
                      {Math.abs((upcomingInvoice?.total - upcomingInvoice?.amount_due) / 100)} {capitalize(upcomingInvoice?.currency)}
                    </Type>
                  )}

                  {...listItemProps}
                />
              )}

              <ListItem
                primary={(
                  <Type
                    version='t3'
                  >
                    Pay now
                  </Type>
                )}

                end={(
                  <Type
                    version='b2'
                  >
                    {upcomingInvoice?.amount_due / 100} {upcomingInvoice?.currency}
                  </Type>
                )}

                {...listItemProps}
              />
            </Line>

            {!paymentMethods.length ? (
              <Line
                fullWidth
              >
                <Button
                  onClick={onAddPaymentCard}

                  version='outlined'

                  color='inherit'

                  size='regular'
                >
                  Add payment method
                </Button>
              </Line>
            ) : <>
              <Input
                description='Choose a payment method'
              >
                <Select
                  version='outlined'

                  valueDefault={paymentMethod}

                  value={paymentMethod}

                  onChange={(valueNew: any) => setPaymentMethod(valueNew)}

                  getname={(item: any) => item.props.primary}

                  fullWidth
                >
                  {paymentMethods.map((item: any, index: number) => (
                    <ListItem
                      key={index}

                      primary={`**** **** **** ${item.card.last4}`}

                      value={item}

                      button

                      noBackground
                    />
                  ))}
                </Select>
              </Input>

              <Line
                direction='row'

                justify='center'

                fullWidth
              >
                <Button
                  version='filled'

                  color='primary'

                  onClick={onUpdate}

                  loading={loading === 'pay'}
                >
                  Update
                </Button>
              </Line>
            </>}
          </Line>
        </Line>
      </Modal>
    </>}
  </>;

  const tabs = React.useMemo(() => {
    return [
      { name: 'Manage', Icon: IconMaterialAccountBalanceWalletW100Rounded },
      { name: 'Payment cards', Icon: IconMaterialCreditCardW100Rounded },
      { name: 'Invoices', Icon: IconMaterialReceiptW100Rounded }
    ];
  }, []);

  return (
    <Line
      flex

      fullWidth
    >
      <Tabs
        value={tab}

        onChange={onChangeTabs}

        justify='center'

        size='small'

        noDivider
      >
        {tabs?.map((item: any) => (
          <Tab
            key={item.name}

            value={item.name}

            icon={item.Icon ? <item.Icon {...iconProps} /> : null}

            style={{
              maxWidth: 'unset'
            }}
          >
            <Type
              version='t2'

              whiteSpace='nowrap'
            >
              {item.name}
            </Type>
          </Tab>
        ))}
      </Tabs>

      <Line
        gap={0}

        flex

        fullWidth
      >
        {tab === 'Manage' && main}

        {tab === 'Payment cards' && paymentCards}

        {tab === 'Invoices' && invoices}
      </Line>
    </Line>
  );
});

export default Element;