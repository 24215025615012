import React from 'react';

import { NumericTextField } from '@amaui/ui-react';
import { INumericTextField } from '@amaui/ui-react/NumericTextField/NumericTextField';
import { classNames, style } from '@amaui/style-react';

import IconMaterialKeyboardArrowUpW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialKeyboardArrowUpW100';
import IconMaterialKeyboardArrowDownW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialKeyboardArrowDownW100';
import IconMaterialCloseW100Rounded from '@amaui/icons-material-rounded-react/IconMaterialCloseW100';

const useStyle = style(theme => ({
  root: {

  }
}), { name: 'amaui-app-NumericTextField' });

const Element = React.forwardRef((props: INumericTextField, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle(props);

  return (
    <NumericTextField
      ref={ref}

      version='outlined'

      size='small'

      IconClear={IconMaterialCloseW100Rounded}

      IconIncrement={IconMaterialKeyboardArrowUpW100Rounded}

      IconDecrement={IconMaterialKeyboardArrowDownW100Rounded}

      IconButtonProps={{
        size: 'regular'
      }}

      IconProps={{
        size: 'large'
      }}

      className={classNames([
        classes.root,
        className
      ])}

      {...other}
    />
  );
});

Element.displayName = 'amaui-app-NumericTextField';

export default Element;
