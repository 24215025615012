import React from 'react';

import { AudioRecorder } from '@amaui/ui-react'
import { IAudioRecorder } from '@amaui/ui-react/AudioRecorder/AudioRecorder';

import IconMaterialMicRounded from '@amaui/icons-material-rounded-react/IconMaterialMicW100';
import IconMaterialSendRounded from '@amaui/icons-material-rounded-react/IconMaterialSendW100';
import IconMaterialPlayArrowRounded from '@amaui/icons-material-rounded-react/IconMaterialPlayArrowW100';
import IconMaterialPauseRounded from '@amaui/icons-material-rounded-react/IconMaterialPauseW100';
import IconMaterialStopRounded from '@amaui/icons-material-rounded-react/IconMaterialStopW100';

const Element: React.FC<IAudioRecorder> = props => {

  return (
    <AudioRecorder
      Icon={IconMaterialMicRounded}

      IconConfirm={IconMaterialSendRounded}

      IconStart={IconMaterialPlayArrowRounded}

      IconPause={IconMaterialPauseRounded}

      IconStop={IconMaterialStopRounded}

      IconProps={{
        size: props.size || 'large'
      }}

      {...props}
    />
  );
};

export default Element;
